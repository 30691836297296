<template>
  <div class="sidebar">
    <div
      class="item"
      v-for="(item, index) in menuItems"
      :key="item.text"
      @mouseenter="item.hover = true"
      @mouseleave="item.hover = false"
    >
      <img alt="" class="icon" :src="item.hover ? item.active : item.icon" />
      <!-- <img :src="item.active" alt="" class="icon" v-show="activeIndex === index" /> -->
      <span>{{ item.text }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const activeIndex = ref(null)
const onMouseEnter = () => {
  this.currentIndex = 1 // 切换为鼠标移入的图片
}
const onMouseLeave = () => {
  this.currentIndex = 0 // 切换为默认图片
}
const currentImage = (index) => {
  return hoverStates[index] ? menuItems.value[index].active : menuItems.value[index].icon
}
const menuItems = ref([
  {
    icon: new URL('@assets/icons/nav/side-1.webp', import.meta.url).href,
    active: new URL('@assets/icons/nav/side-1_1.webp', import.meta.url).href,
    text: '赛程'
  },
  {
    icon: new URL('@assets/icons/nav/side-2.webp', import.meta.url).href,
    active: new URL('@assets/icons/nav/side-2_1.webp', import.meta.url).href,
    text: '榜单'
  },
  {
    icon: new URL('@assets/icons/nav/side-3.webp', import.meta.url).href,
    active: new URL('@assets/icons/nav/side-3_1.webp', import.meta.url).href,
    text: '关注'
  },
  {
    icon: new URL('@assets/icons/nav/side-4.webp', import.meta.url).href,
    active: new URL('@assets/icons/nav/side-4_1.webp', import.meta.url).href,
    text: '预约'
  },
  {
    icon: new URL('@assets/icons/nav/side-5.webp', import.meta.url).href,
    active: new URL('@assets/icons/nav/side-5_1.webp', import.meta.url).href,
    text: '全部'
  },
  {
    icon: new URL('@assets/icons/nav/side-6.webp', import.meta.url).href,
    active: new URL('@assets/icons/nav/side-6_1.webp', import.meta.url).href,
    text: '足球'
  },
  {
    icon: new URL('@assets/icons/nav/side-7.webp', import.meta.url).href,
    active: new URL('@assets/icons/nav/side-7_1.webp', import.meta.url).href,
    text: '篮球'
  },
  {
    icon: new URL('@assets/icons/nav/side-8.webp', import.meta.url).href,
    active: new URL('@assets/icons/nav/side-8_1.webp', import.meta.url).href,
    text: '电竞'
  },
  {
    icon: new URL('@assets/icons/nav/side-11.webp', import.meta.url).href,
    active: new URL('@assets/icons/nav/side-11_1.webp', import.meta.url).href,
    text: '下载',
    position: 'bottom'
  },
  {
    icon: new URL('@assets/icons/nav/side-12.webp', import.meta.url).href,
    active: new URL('@assets/icons/nav/side-12_1.webp', import.meta.url).href,
    text: '做主播',
    position: 'bottom'
  }
])
</script>

<style scoped lang="scss">
.sidebar {
  //   width: 60px;
  background-color: $components-sidebar-bg;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0;
  padding-top: 40px;
  position: fixed;
  left: 0;
  top: 60px;
  z-index: 5;
  width: 60px;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  bottom: 0;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }

    .icon {
      width: 24px;
      height: 24px;
      margin-bottom: 5px;
    }
    span {
      color: $components-sidebar-text;
      font-size: 12px;
    }
    &:hover {
      span {
        color: $components-sidebar-text-hover;
      }
    }
  }
}
</style>
