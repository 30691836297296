<template>
  <div class="match-schedule" v-if="events?.length">
    <!-- 左侧选项卡 -->
    <div class="period-tabs">
      <div v-for="(period, index) in periods" :key="index"
        :class="['tab-item', { active: currentPeriod === period.name }]" @click="switchPeriod(period.name)">
        {{ period.name }}
      </div>
    </div>

    <!-- 右侧时间线内容 -->
    <div class="timeline-content">
      <!-- 第一条数据（空数据，只有绿点） -->
      <div class="timeline-item empty">
        <div class="timeline-left">
          <div class="dot"></div>
          <div class="time"></div>
        </div>
        <div class="timeline-right">
          <div class="period-time"></div>
          <div class="event-content">
          </div>
        </div>
      </div>

      <!-- 可滚动的数据容器 -->
      <div class="timeline-scroll">
        <template v-if="filteredEvents.length">
          <!-- 常规数据行 -->
          <div v-for="(event, index) in filteredEvents" :key="index"
            :class="['timeline-item', { 'with-border': index !== filteredEvents.length - 1 }]">
            <div class="timeline-left">
              <div class="time">{{ formatTime(event.occurTime) }}</div>
            </div>
            <div class="timeline-right">
              <div class="period-time">{{ event.scores }}</div>
              <div class="event-content">
                <div class="event-text">{{ event.content }}</div>
                <img v-if="event.iconType && event.iconType !== ''" :src="event.iconType" class="team-flag" />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <!-- 空数据状态 -->
          <div class="empty-state">
            <div class="empty-text">暂无数据</div>
          </div>
        </template>
      </div>
      <!-- 底部空数据（只有绿点） -->
      <div class="timeline-item empty">
        <div class="timeline-left">
          <div class="dot no-animation"></div>
          <div class="time"></div>
        </div>
        <div class="timeline-right">
          <div class="period-time"></div>
          <div class="event-content">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="empty" v-else>
    <img src="@assets/imgs/empty.webp" class="empty-icons" />
    <p>暂无数据</p>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, reactive } from 'vue'
import { getBasketballTextLive } from '@/api/match'
import { useRoute } from 'vue-router'
// State
// 获取路由实例
const route = useRoute()
const currentPeriod = ref('第一节')
const events = reactive([])
const isFirstLoad = ref(true)
let timer = null

const fetchTextLive = async () => {
  try {
    const res = await getBasketballTextLive({ matchId: route.query.id })
    if (res.data && res.data.events) {
      // 使用 splice 方法替代直接赋值
      events.splice(0, events.length, ...res.data.events)

      // 只在第一次加载时设置 currentPeriod
      if (isFirstLoad.value && res.data.events.length > 0) {
        currentPeriod.value = res.data.events[0].statusName || '第一节'
        isFirstLoad.value = false
      }
    }
  } catch (error) {
    console.error('获取文字直播失败:', error)
  }
}
// 添加时间格式化函数
const formatTime = (seconds) => {
  if (!seconds && seconds !== 0) return ''

  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)

  // 分钟和秒都使用 padStart 保持两位数
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
}
onMounted(() => {
  // 立即执行一次
  fetchTextLive()

  // 每3秒执行一次
  timer = setInterval(() => {
    fetchTextLive()
  }, 5000)
})

onUnmounted(() => {
  if (timer) {
    clearInterval(timer)
  }
})
// 根据matchType动态设置periods
const periods = computed(() => {
  return [
    { id: 4, name: '第四节' },
    { id: 3, name: '第三节' },
    { id: 2, name: '第二节' },
    { id: 1, name: '第一节' }
  ]
})


// Computed
const filteredEvents = computed(() => {
  return events.filter(event => {
    const eventStatus = event.statusName || '第一节'
    return eventStatus === currentPeriod.value
  })
})

// Methods
const switchPeriod = (periodId) => {
  currentPeriod.value = periodId
}
</script>

<style lang="scss" scoped>
.match-schedule {
  display: flex;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
}

.period-tabs {
  width: 120px;
  border-right: 1px solid $liveDetail-basketballText-border;
}

.tab-item {
  padding: 12px 20px;
  cursor: pointer;
  color: $liveDetail-basketballText-tabText;
  border: 1px solid $liveDetail-basketballText-tabBorder;
  border-radius: 4px;
  border-right: none;
  margin-right: -1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
}

.tab-item.active {
  color: $liveDetail-basketballText-white;
  background: $liveDetail-basketballText-tabActiveBg;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
}

.tab-item:last-child {
  margin-bottom: 0;
}

.timeline-content {
  flex: 1;
  padding-left: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.timeline-scroll {
  flex: 1;
  overflow-y: auto;
  max-height: 540px;
  padding-right: 10px;
}

.timeline-scroll::-webkit-scrollbar {
  width: 4px;
}

.timeline-scroll::-webkit-scrollbar-track {
  background: $liveDetail-basketballText-scrollTrack;
  border-radius: 2px;
}

.timeline-scroll::-webkit-scrollbar-thumb {
  background: $liveDetail-basketballText-scrollThumb;
  border-radius: 2px;
}

.timeline-scroll::-webkit-scrollbar-thumb:hover {
  background: $liveDetail-basketballText-scrollThumbHover;
}

.timeline-content::before {
  content: '';
  position: absolute;
  left: 60px;
  top: 30px;
  bottom: 55px;
  width: 1px;
  background: $liveDetail-basketballText-timeline;
  z-index: 1;
}

.timeline-item {
  display: flex;
  padding: 8px 0;
  position: relative;
  font-size: 14px;
}

.timeline-item.empty {
  height: 40px;
  width: 10px;
  margin-bottom: 16px;
  align-items: start;
}

.timeline-item.with-border {
  margin-bottom: 16px;
}

.timeline-item.with-border::after {
  content: '';
  position: absolute;
  left: 120px;
  right: 0;
  bottom: -8px;
  height: 1px;
  background: $liveDetail-basketballText-itemBorder;
}

.timeline-left {
  width: 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $liveDetail-basketballText-dotBg;
  z-index: 2;
  margin-bottom: 8px;
  position: relative;
  box-shadow: 0 0 0 2px $liveDetail-basketballText-white;
}

.dot::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background: rgba($liveDetail-basketballText-dotBg, 0.3);
  border-radius: 50%;
  animation: pulse 1.5s ease-in-out infinite;
  z-index: 1;
}

@keyframes pulse {
  0% {
    width: 10px;
    height: 10px;
    opacity: 1;
  }

  100% {
    width: 26px;
    height: 26px;
    opacity: 0;
  }
}

.time {
  color: $liveDetail-basketballText-tabText;
  font-size: 14px;
  background: $liveDetail-basketballText-white;
  padding: 0 8px;
  position: relative;
  z-index: 2;
}

.timeline-right {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-left: 20px;
  padding-left: 20px;
  min-width: 0;
}

.period-time {
  width: 80px;
  color: $liveDetail-basketballText-tabText;
  font-size: 14px;
  flex-shrink: 0;
  margin-right: 10px;
}

.event-content {
  flex: 1;
  display: flex;
  align-items: flex-start;
  color: $liveDetail-basketballText-tabText;
  min-width: 0;
}

.event-text {
  flex: 1;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  margin-right: 10px;
  min-width: 0;
  font-size: 14px;
}

.team-flag {
  width: 20px;
  height: 20px;
  object-fit: cover;
  flex-shrink: 0;
}

.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.empty-text {
  color: $liveDetail-basketballText-tabText;
  font-size: 14px;
}

.dot.no-animation::after {
  display: none;
  /* 移除动画元素 */
}
.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: $liveDetail-criticalIncident-text-gray;
  .empty-icons {
    width: 300px;
    height: 300px;
    // margin-bottom: 20px;
  }
}
</style>