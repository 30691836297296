

type EventLegendIcon = {
    [key: string]: {
        key: number;
        name: string;
        src: string;
    };
};


export const occurTime = (time: number) => {
    return Math.round(time / 60) + '\''
}

export const eventLegendIcon: EventLegendIcon = {
    '进球': {key: 9, name: '进球', src: '/src/assets/icons/score/goal.webp'},
    '点球': {key: 0, name: '点球', src: '/src/assets/icons/score/penalty.webp'},
    '点球不进': {key: 0, name: '点球不进', src: '/src/assets/icons/score/penalty_miss.webp'},
    '比赛开始': {key: 1, name: '比赛开始', src: '/src/assets/icons/score/whistle.webp'},
    '默认': {key: 34, name: '默认', src: '/src/assets/icons/score/default_score.webp'},
    '乌龙球': {key: 0, name: '乌龙球', src: '/src/assets/icons/score/own_goal.webp'},
    '角球': {key: 30, name: '角球', src: '/src/assets/icons/score/corner.webp'},
    '助攻': {key: 0, name: '助攻', src: '/src/assets/icons/score/assist.webp'},
    '黄牌': {key: 18, name: '黄牌', src: '/src/assets/icons/score/yellow_card.webp'},
    '红牌': {key: 0, name: '红牌', src: '/src/assets/icons/score/red_card.webp'},
    '两黄一红': {key: 21, name: '两黄一红', src: '/src/assets/icons/score/yellow_red_card.webp'},
    '换人': {key: 23, name: '换人', src: '/src/assets/icons/score/substitution.webp'},
    '受伤': {key: 0, name: '受伤', src: '/src/assets/icons/score/injury.webp'},
    'VAR': {key: 0, name: 'VAR', src: '/src/assets/icons/score/var.webp'},
}


export const mockData = {
    "homeTeamSchedule": [
        {
            "leagueId": 76,
            "matchName": "拉脱超",
            "matchTime": "19:00",
            "matchDate": "2024-11-03",
            "homeTeamName": "里加",
            "homeTeamNormalScore": 0,
            "awayTeamName": "美塔里加",
            "awayTeamNormalScore": 0,
            "intervalDays": 5,
            "matchId": 9464980,
            "status": 1,
            "matchStatus": 1,
            "matchStatusCode": 0,
            "matchStatusDesc": "未开始",
            "matchFullTime": 1730631600000,
            "odds": null
        },
        {
            "leagueId": 76,
            "matchName": "拉脱超",
            "matchTime": "19:00",
            "matchDate": "2024-11-09",
            "homeTeamName": "奥达",
            "homeTeamNormalScore": 0,
            "awayTeamName": "美塔里加",
            "awayTeamNormalScore": 0,
            "intervalDays": 11,
            "matchId": 9464987,
            "status": 1,
            "matchStatus": 1,
            "matchStatusCode": 0,
            "matchStatusDesc": "未开始",
            "matchFullTime": 1731150000000,
            "odds": null
        }
    ],
    "awayTeamSchedule": [
        {
            "leagueId": 283,
            "matchName": "拉脱杯",
            "matchTime": "01:45",
            "matchDate": "2024-10-31",
            "homeTeamName": "列加斯",
            "homeTeamNormalScore": 0,
            "awayTeamName": "奥达",
            "awayTeamNormalScore": 0,
            "intervalDays": 2,
            "matchId": 10685842,
            "status": 1,
            "matchStatus": 1,
            "matchStatusCode": 0,
            "matchStatusDesc": "未开始",
            "matchFullTime": 1730310300000,
            "odds": null
        },
        {
            "leagueId": 76,
            "matchName": "拉脱超",
            "matchTime": "19:00",
            "matchDate": "2024-11-03",
            "homeTeamName": "格罗比纳斯",
            "homeTeamNormalScore": 0,
            "awayTeamName": "列加斯",
            "awayTeamNormalScore": 0,
            "intervalDays": 5,
            "matchId": 9464979,
            "status": 1,
            "matchStatus": 1,
            "matchStatusCode": 0,
            "matchStatusDesc": "未开始",
            "matchFullTime": 1730631600000,
            "odds": null
        },
        {
            "leagueId": 239,
            "matchName": "欧联杯",
            "matchTime": "04:00",
            "matchDate": "2024-11-08",
            "homeTeamName": "列加斯",
            "homeTeamNormalScore": 0,
            "awayTeamName": "安德莱赫特",
            "awayTeamNormalScore": 0,
            "intervalDays": 10,
            "matchId": 10596907,
            "status": 1,
            "matchStatus": 1,
            "matchStatusCode": 0,
            "matchStatusDesc": "未开始",
            "matchFullTime": 1731009600000,
            "odds": null
        }
    ]
}
