<template>
  <div class="header">
    <div class="left-section">
      <div class="avatar">
        <img :src="anchorData?.headImageUrl || defaultAvatar" alt="Player" class="player-image" />
      </div>
      <div>
        <div class="match-info">
          <span class="match-type">{{ data?.liveTitle || '懂比分直播间' }}</span>
        </div>
        <div class="additional-info">
          <span class="live-icon">{{ anchorData?.nickname || '-' }}</span>
          <span class="room-id">房间号 {{ anchorData?.roomRecordId || '-' }}</span>
          <span class="viewers">
            <img src="@assets/icons/live-detail/fire.webp" class="icon" />
            {{ anchorData?.hotCount || '-' }}
          </span>
          <span class="host-id">主播ID {{ anchorData?.userId || '-' }}</span>
        </div>
      </div>
    </div>
    <div class="right-section">
      <div>
        <button class="follow">+ 关注</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, watch, ref, reactive } from 'vue'
import dayjs from 'dayjs'
import defaultAvatar from '@assets/imgs/default-head-1.webp'
const myProps = defineProps(['stream', 'anchor'])
// console.log(myProps.match)
let data = reactive({})
let anchorData = reactive({})
const formatTime = (num) => {
  return dayjs(num).format('YYYY-MM-DD HH:mm:ss')
}

watch(
  () => myProps.stream,
  (newProps) => {
    console.log(newProps)
    data = newProps
  }
)

watch(
  () => myProps.anchor,
  (newProps) => {
    console.log(newProps)
    anchorData = newProps
  }
)
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   background-color: #f4f7fa;
  padding: 10px 20px;
  border-radius: 10px;
  font-family: Arial, sans-serif;

  .left-section {
    display: flex;
    align-items: center;
    width: 600px;
    .player-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .match-info {
      display: flex;
      flex-direction: column;
      margin-right: 20px;

      .match-type {
        font-weight: 500;
        font-size: 20px;
        color: $liveDetail-playerHeader-text-primary;
      }

      .teams {
        font-size: 18px;
        font-weight: bold;
        color: $liveDetail-playerHeader-text-secondary;
      }
    }

    .additional-info {
      display: flex;
      //   flex-direction: column;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      color: $liveDetail-playerHeader-text-light;

      .live-icon,
      .room-id,
      .viewers,
      .host-id {
        margin-top: 5px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        .icon {
          width: 14px;
          margin-right: 5px;
        }
      }
    }
  }

  .right-section {
    display: flex;
    // align-items: center;
    flex-direction: column;
    align-items: flex-end;
    // justify-content: flex-end;
    font-weight: 400;
    font-size: 12px;
    color: $liveDetail-playerHeader-text-white;
    .followed {
      background-color: $liveDetail-playerHeader-text-white;
      color: $liveDetail-playerHeader-text-gray;
      border: 1px solid $liveDetail-playerHeader-text-light;
      border-radius: 20px;
      padding: 5px 15px;
      margin-right: 10px;
      cursor: pointer;
    }

    .follow {
      background-color: $liveDetail-playerHeader-button-follow;
      color: $liveDetail-playerHeader-text-white;
      border: none;
      border-radius: 20px;
      padding: 5px 15px;
      cursor: pointer;
    }

    .actions {
      display: flex;
      align-items: center;
      margin-left: 20px;
      color: $liveDetail-playerHeader-text-gray;
      padding-top: 5px;
      .report {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 10px;
          margin: 0 10px;
          margin-left: 10px;
          margin-right: 2px;
        }
      }
      span {
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}
</style>
