<template>
  <div class="match-container">
    <div class="team">
      <div class="team-details">
        <span class="team-name">{{ data?.home_team?.name }}</span>
        <!-- <span class="league">[欧盟04]</span> -->
      </div>
      <img :src="data?.home_team?.logo" alt="Portugal Flag" class="flag" />
    </div>
    <div class="score">{{ data?.home_total_score }}</div>
    <div class="match-info">
      <div class="date">{{ formatTime(data?.match_time) }} {{ data?.league_name }}</div>
      <div class="vs">{{ data?.match_status_txt ? data?.match_status_txt : '未开赛' }}</div>
      <div class="more-info">更多数据</div>
    </div>
    <div class="score">{{ data?.guest_total_score }}</div>
    <div class="team">
      <img :src="data?.guest_team?.logo" alt="Portugal Flag" class="flag" />
      <div class="team-details">
        <span class="team-name">{{ data?.guest_team?.name }}</span>
        <!-- <span class="league">[欧盟18]</span> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, watch, ref, reactive } from 'vue'
import dayjs from 'dayjs'
const myProps = defineProps(['match'])
// console.log(myProps.match)
let data = reactive({})
const formatTime = (num) => {
  return dayjs(num).format('YYYY-MM-DD HH:mm:ss')
}
watch(
  () => myProps.match,
  (newProps) => {
    console.log(newProps)
    data = myProps.match
  },
  { immediate: true }
)
</script>

<style scoped lang="scss">
.match-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $liveDetail-header-bg;
  padding: 10px;
  border-radius: 10px;
  width: 860px;
  height: 60px;
  box-sizing: border-box;
  // margin-top: 20px;
  margin-bottom: 20px;

  .team {
    display: flex;
    // flex-direction: column;
    align-items: center;
    color: $liveDetail-header-text;

    .flag {
      width: 38px;
      height: 38px;
      margin: 0 5px;
    }

    .team-details {
      display: flex;
      flex-direction: column;
      align-items: center;

      .team-name {
        font-weight: 500;
        font-size: 18px;
        color: $liveDetail-header-text;
      }

      .league {
        font-size: 14px;
      }
    }
  }

  .score {
    font-weight: 500;
    font-size: 36px;
    color: $liveDetail-header-text;
    margin: 0 40px;
    &:first-child {
    }
    &:last-child {
      margin-left: 20px;
    }
  }

  .match-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $liveDetail-header-text;

    .date,
    .match-type,
    .vs,
    .more-info {
      font-weight: 400;
      font-size: 12px;
      color: $liveDetail-header-text;
    }

    .vs {
      font-weight: 500;
      font-size: 12px;
      color: $liveDetail-header-status;
    }
  }
}
</style>
