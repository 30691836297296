<template>
  <div class="tournament-info">
    <div class="incident" v-for="(item, index) in props.arrData" :key="item.eventId">
      <div class="left-info">
        <div v-show="item.team === 1">
          <div class="goal-row" v-if="item.typeId === 9">
            <div>
              <div class="statistic-row">
                <div>
                  <div class="statistic-row-name">
                    <p>
                      <span>{{ item.playerName }}</span>
                    </p>
                    <p v-if="item.playerName2">
                      <span>{{ item.playerName2 }}</span>
                      <img src="@assets/icons/score/assist.webp" alt="" />
                    </p>
                  </div>
                </div>
              </div>
              <generateScoreHtml :score="item.content2" :team="item.team"></generateScoreHtml>

              <img class="img-icon" src="@assets/icons/score/goal.webp" alt="" />
            </div>
          </div>

          <div class="statistic-row" v-else>
            <div v-if="[30, 21, 18].includes(item.typeId)">
              <div class="statistic-row-name">
                <p>{{ item.typeId === 18 ? item.playerName : item.content }}</p>
              </div>
              <div class="statistic-row-icon">
                <img class="img-icon" :src="arrDataIcon[item.typeId].src" alt="" />
              </div>
            </div>

            <div v-if="[23].includes(item.typeId)">
              <div class="statistic-row">
                <div>
                  <div class="statistic-row-name">
                    <p>
                      <img src="@assets/icons/score/green_top.webp" alt="" />
                      <span>{{ item.playerName }}</span>
                    </p>
                    <p>
                      <img src="@assets/icons/score/red_bottom.webp" alt="" />
                      <span>{{ item.playerName2 }}</span>
                    </p>
                  </div>
                  <div class="statistic-row-icon">
                    <img class="img-icon" :src="arrDataIcon[item.typeId].src" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="time">{{ occurTime(item.occurTime) }}</div>

      <div class="right-info">
        <div v-show="item.team === 2">
          <div class="goal-row" v-if="item.typeId === 9">
            <div>
              <img class="img-icon" src="@assets/icons/score/goal.webp" alt="" />
              <generateScoreHtml :score="item.content2" :team="1"></generateScoreHtml>
              <div class="statistic-row">
                <div>
                  <div class="statistic-row-name">
                    <p>
                      <span>{{ item.playerName }}</span>
                    </p>
                    <p v-if="item.playerName2">
                      <span>{{ item.playerName2 }}</span>
                      <img :src="arrDataIcon[item.typeId].src" alt="" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="statistic-row" v-else>
            <div v-if="[30, 21, 18].includes(item.typeId)">
              <div class="statistic-row-icon">
                <img class="img-icon" :src="arrDataIcon[item.typeId].src" alt="" />
              </div>
              <div class="statistic-row-name">
                <p>{{ item.typeId === 18 ? item.playerName : item.content }}</p>
              </div>
            </div>

            <div v-if="[23].includes(item.typeId)">
              <div class="statistic-row">
                <div>
                  <div class="statistic-row-icon">
                    <img class="img-icon" :src="arrDataIcon[item.typeId].src" alt="" />
                  </div>
                  <div class="statistic-row-name">
                    <p>
                      <span>{{ item.playerName }}</span>

                      <img src="@assets/icons/score/green_top.webp" alt="" />
                    </p>
                    <p>
                      <span>{{ item.playerName2 }}</span>

                      <img src="@assets/icons/score/red_bottom.webp" alt="" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { h, defineProps } from 'vue'

const props = defineProps<{
  arrData: any
  arrDataIcon: any
}>()

const occurTime = (time: number) => {
  return Math.round(time / 60) + "'"
}

const generateScoreHtml = (props: any) => {
  let str = props.score.split('-')
  // return `
  //                     <span class="active-score">${str[0]}</span>
  //                   <i>-</i>
  //                   <span>${str[1]}</span>
  // `
  //     <p class="score">
  // <span class="active-score">0</span>
  //     <i>-</i>
  //     <span>3</span>
  //     </p>
  // return h('p', { class: 'score' }, [h('span', str[0]), h('i', '-'), h('span', str[1])]);
  return h('p', { class: 'score' }, [
    h('span', { class: props.team === 1 ? 'active-score' : '' }, str[0]),
    h('i', ' - '),
    h('span', { class: props.team === 1 ? '' : 'active-score' }, str[1])
  ])
}
</script>

<style scoped lang="scss">
.tournament-info {
  min-height: 300px;
  margin: 16px 0 30px;
  border-radius: 20px;
  border: 1px solid $liveDetail-criticalIncidentSlot-border;

  .incident {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    border: 1px solid $liveDetail-criticalIncidentSlot-border;

    .time {
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      color: $liveDetail-criticalIncidentSlot-time;
      //flex-grow: 0;
      flex: 1;
    }

    .left-info,
    .right-info {
      min-width: 1px;
      height: 100%;
      flex: 4;
    }

    .right-info {
      .statistic-row,
      .goal-row {
        justify-content: left !important;
      }
    }

    .left-info .statistic-row-name {
      p {
        justify-content: end;
      }
    }
    .left-info .statistic-row-icon {
      margin-left: 8px;
    }
    .right-info .statistic-row-icon {
      margin-right: 8px;
    }

    .goal-row {
      display: flex;
      justify-content: flex-end;
      > div {
        display: flex;
        align-items: center;
        .score {
          width: 54px;
          height: 30px;
          margin: 0 8px;
          line-height: 30px;
          text-align: center;
          background: $liveDetail-criticalIncidentSlot-score-bg;
          border-radius: 4px;
          font-weight: 500;
          font-size: 18px;
          color: $liveDetail-criticalIncidentSlot-text;
          :deep(.active-score) {
            color: $liveDetail-criticalIncidentSlot-score-active;
          }
        }
      }
    }

    .statistic-row {
      display: flex;
      justify-content: right;
      > div {
        display: flex;
        align-items: center;
        div.statistic-row-name {
          p {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: $liveDetail-criticalIncidentSlot-player-name;
            img {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
