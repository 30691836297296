<template>
  <div class="event-section">
    <div class="header">
      <span class="title">{{props.title}}</span>
      <a-select class="select" v-model:value="filterValue" style="width: 100px">
        <a-select-option value="all">全场</a-select-option>
        <a-select-option value="1">半场</a-select-option>
      </a-select>
    </div>

    <div class="header-line"></div>
    <div class="teams-container">
      <!-- 左队 -->
      <div class="team-table">
        <div class="team-header">
          <img v-if="tableData.host.logo" :src="tableData.host.logo" class="team-flag"/>
          <span>{{tableData.host.name}}</span>
        </div>

        <a-table   class="default_table" :columns="columns" :data-source="tableData.host.data || []"
                   :row-class-name="(_record: any, index:number) => (index % 2 === 1 ? null : 'table-striped')"
                   :pagination="false"
                   size="small">

          <!--      top      -->
          <template #bodyCell="{text, record, index, column}">
            <template v-if="column.dataIndex === 'index'">
              <span class="table_describe">{{ ['总', '主', '客'][index] }}</span>
            </template>

            <template v-if="column.dataIndex === 'points'">
              <span :style="{color: '#E95D5D'}">{{text}}</span>
            </template>

            <template v-if="column.dataIndex === 'ballPossession'">
              <span >{{text}}%</span>
            </template>

            <template v-if="column.dataIndex === 'record'">
              <span>{{record.win}}-{{record.draw}}-{{record.lost}}</span>
            </template>

            <template v-if="column.dataIndex === 'encounter'">
              <span>{{record.homeTeamName}} VS {{record.awayTeamName}}</span>
            </template>

            <template v-if="column.dataIndex === 'matchFullTime'">
              <span>{{daysToText(record.matchFullTime)}}</span>
            </template>

            <template v-if="column.dataIndex === 'diff'">
              <span>{{isNaN(record.matchCount - record.lostGoal) ? '' : record.matchCount - record.lostGoal}}</span>
            </template>

            <template v-if="column.dataIndex === 'rate'">
              <span>{{ (((record.win / (record.win + record.draw + record.lost)) * 100) || 0).toFixed(2) + '%'}}</span>
            </template>
          </template>


          <template #footer v-if="title === '赛果盘路统计'">
            <div class="table-footer">
              <p class="title">近6���</p>
              <p>
                <span v-for="item in tableData.host.asiaDesc" :class="[item === '赢' ? 'red' : item === '输' ? 'blue' : 'default_color']"> {{item}}</span>
              </p>
              <p>
                <span v-for="item in tableData.host.dxDesc" :class="[item === '大' ? 'red' : item === '小' ? 'blue' : 'default_color']"> {{item}}</span>

              </p>
            </div>
          </template>

        </a-table>
      </div>

      <!-- 右队 -->
      <div class="team-table">
        <div class="team-header">
          <img v-if="tableData.guest.logo" :src="tableData.guest.logo" class="team-flag"/>
          <span>{{tableData.guest.name}}</span>
        </div>

        <a-table   class="default_table" :columns="columns" :data-source="tableData.guest.data || []"
                   :row-class-name="(_record: any, index:number) => (index % 2 === 1 ? null : 'table-striped')"
                   :pagination="false"
                   size="small">

          <!--      积分排名-Table-1      -->
          <template #bodyCell="{text, record, index, column}">
            <template v-if="column.dataIndex === 'index'">
              <span class="table_describe">{{ ['总', '主', '客'][index] }}</span>
            </template>

            <template v-if="column.dataIndex === 'points'">
              <span :style="{color: '#E95D5D'}">{{text}}</span>
            </template>

            <template v-if="column.dataIndex === 'ballPossession'">
              <span >{{text}}%</span>
            </template>

            <template v-if="column.dataIndex === 'record'">
              <span>{{record.win}}-{{record.draw}}-{{record.lost}}</span>
            </template>

            <template v-if="column.dataIndex === 'encounter'">
              <span>{{record.homeTeamName}} VS {{record.awayTeamName}}</span>
            </template>

            <template v-if="column.dataIndex === 'matchFullTime'">
              <span>{{daysToText(record.matchFullTime)}}</span>
            </template>

            <template v-if="column.dataIndex === 'diff'">
              <span>{{isNaN(record.matchCount - record.lostGoal) ? '' : record.matchCount - record.lostGoal}}</span>
            </template>

            <template v-if="column.dataIndex === 'rate'">
              <span>{{ (((record.win / (record.win + record.draw + record.lost)) * 100) || 0).toFixed(2) + '%'}}</span>
            </template>
          </template>

          <template #footer v-if="title === '赛果盘路统计'">
            <div class="table-footer">
              <p class="title">近6场</p>
              <p>
                <span v-for="item in tableData.guest.asiaDesc" :class="[item === '赢' ? 'red' : item === '输' ? 'blue' : 'default_color']"> {{item}}</span>
              </p>
              <p>
                <span v-for="item in tableData.guest.dxDesc" :class="[item === '大' ? 'red' : item === '小' ? 'blue' : 'default_color']"> {{item}}</span>

              </p>
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>



<script setup lang="ts">
// 示例数据
import {reactive, ref} from "vue";
import type {TableColumnsType} from "ant-design-vue";

const props = defineProps<{
  title: string
  tableData: any
  columns: any
}>()
const { title, tableData, columns } = props;
interface RankingData {
  type: string;
  rank: number;
  record: string;
  goals: number;
  diff: number;
  points: number;
  rate: string;
}

// const columns: TableColumnsType<RankingData> = [
//   {title: '', dataIndex: 'index', width: 60},
//   {title: '排名', dataIndex: 'rank', width: 60},
//   {title: '胜平负', dataIndex: 'record', width: 100},
//   {title: '得失', dataIndex: 'goals', width: 60},
//   {title: '净胜', dataIndex: 'diff', width: 60},
//   {title: '积分', dataIndex: 'points', width: 60},
//   {title: '胜率', dataIndex: 'rate', width: 80}
// ]

const homeTeamData = ref<RankingData[]>([
  {type: '总', rank: 6, record: '11-9-9', goals: 11, diff: 6, points: 42, rate: '37.93%'},
  {type: '主', rank: 7, record: '6-5-4', goals: 6, diff: 5, points: 23, rate: '40.00%'},
  {type: '客', rank: 6, record: '5-4-5', goals: 5, diff: 1, points: 19, rate: '35.71%'}
])

const filterValue = ref('all')

const approach_6Data = reactive({
  name: '近6场',
  winning: ['赢', '赢', '输', '赢', '赢', '输'],
  betOn: ['小', '大', '平', '小', '大', '小']
})


function daysToText(timestamp) {
  const now = new Date(); // 获取当前时间
  const target = new Date(timestamp); // 将时间戳转换为Date对象
  const differenceInMilliseconds = target - now; // 计算时间差（毫秒）
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24); // 将时间差转换为天数

  // 计算剩余天数，并判断是过去还是未来
  const remainingDays = Math.round(differenceInDays);

  if (remainingDays === 0) {
    return '今天';
  } else if (remainingDays < 0) {
    // 如果是负数，表示时间戳是过去的日期
    return `${-remainingDays}天前`;
  } else {
    // 如果是正数，表示时间戳是未来的日期
    return `${remainingDays}天后`;
  }
}


</script>



<style scoped lang="scss">
.event-section {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .title {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: $matchDetail-statistics-primary;
    }
    .select {
      width: 120px;
      :deep(.ant-select-selector) {
        border-radius: 15px;
        .ant-select-selection-item {
          color: $matchDetail-statistics-select !important;
        }
      }
    }

    .stats {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-left: 16px;
      font-size: 14px;
      color: $matchDetail-statistics-primary;

      .mini-flag {
        width: 20px;
        height: 14px;
      }
    }
  }

  .header-line {
    width: 100%;
    height: 1px;
    margin: 18px 0 15px;
    background-color: $matchDetail-statistics-border;
  }

  .teams-container {
    display: flex;
    gap: 16px;

    .team-table {
      flex: 1;

      .team-header {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 12px;

        .team-flag {
          width: 24px;
          height: 16px;
        }
      }
    }
  }

  :deep(.ant-table) {
    table {
      border: 1px solid $matchDetail-statistics-border;
    }

    .ant-table-cell {
      &::before {
        display: none;
      }
    }

    .ant-table-thead > tr > th {
      background: $matchDetail-statistics-white !important;
      border-bottom: 1px solid $matchDetail-statistics-border;
      color: $matchDetail-statistics-secondary !important;
      font-size: 14px !important;
      font-weight: normal !important;

    }

    .ant-table-thead > tr> th:last-of-type {
      border-right: 1px solid $matchDetail-statistics-border !important;
    }
    .ant-table-thead > tr> th {
      border-inline-end: none!important;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      text-align: center;
      border-color: $matchDetail-statistics-border !important;
      color: $matchDetail-statistics-primary;
      font-size: 12px;

    }
    .table_describe {
      color: $matchDetail-statistics-secondary;
    }

    .table-striped {
      background-color: $matchDetail-statistics-background;
    }


    .table-striped {
      background-color: $matchDetail-statistics-background;
    }
    .ant-table-tbody > tr:hover > td {
      background-color: $matchDetail-statistics-background !important;
    }

    .ant-table-footer {
      border: 1px solid $matchDetail-statistics-border;
      border-top: none;
      background-color: $matchDetail-statistics-white;
      .table-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: $matchDetail-statistics-primary;
        }
        span {
          margin-right: 3px;
        }
      }
    }


  }

  i {
    font-style: normal;
  }
  .red {
    color: $matchDetail-statistics-red;
  }

  .blue {
    color: $matchDetail-statistics-blue;
  }
  .default_color {
    color: $matchDetail-statistics-secondary !important;
  }
}

</style>
