<template>
  <div class="score-container">
    <div class="sidebar">
      <SideBar />
    </div>
    <div class="score-inner-container">
      <div class="live-wrapper">
        <div class="top">
          <MatchList />
        </div>
        <div class="list">
          <div class="tab">
            <button class="button" :class="{ active: activeButton === 'instant' }" @click="setActive('instant')">
              全部
            </button>
          </div>
          <div class="match-cards" v-if="list.length > 0">
            <div class="match-card" v-for="(match, index) in list" :key="index" @click="() => goLive(match)">
              <div class="match-image">
                <img v-lazy="match.liveImage" data-load="false" class="cover" />
                <div class="match-info">
                  <h3>{{ match.title }}</h3>
                  <div class="streamer-info">
                    <img :src="match.headImageUrl" alt="Streamer avatar" class="avatar" />
                    <span class="streamer-name">{{ match.nickName }}</span>
                    <span class="views">{{ formatViews(match.hotCount) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="empty" v-else>
            <img src="@assets/imgs/empty.webp" class="empty-icons" />
            <p>暂无数据</p>
          </div>
        </div>
        <div class="pagination">
          <Pagination v-model:current="current" :total="total" show-less-items @change="paginationHandle"
            :showSizeChanger="false" :defaultPageSize="50" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'
import MatchList from './match-list.vue'
import { getLiveList } from '@/api/live'
import { useRouter, useRoute } from 'vue-router'
import { Pagination } from 'ant-design-vue'
import SideBar from '@/components/sideBar.vue'

// 1. Define all refs first
const list = ref([])
const current = ref(1)
const pageRef = ref(1)
const total = ref(10)
const type = ref(null)
const activeButton = ref('instant')

// 2. Setup router and route
const router = useRouter()
const route = useRoute()

// 3. Define utility functions
const formatViews = (views) => {
  if (views >= 10000) {
    const formattedViews = (views / 10000).toFixed(1)
    return `${formattedViews}w`
  }
  return views.toString()
}

// 4. Define main functions
const getData = async () => {
  try {
    const res = await getLiveList({
      liveGroupId: type.value !== null ? Number(type.value) : null,
      page: pageRef.value,
      size: 50
    })
    list.value = res.data.list
    total.value = res.data.total
    setTimeout(() => {
      window.scrollTo({
        top: 0
      })
    })
  } catch (error) {
    console.error('Error fetching live list:', error)
  }
}

const goLive = (match) => {
  console.log('match', match)
  router.push({
    path: `/live/${match.roomId}`,
    query: {
      id: match.matchId
    }
  })
}

const paginationHandle = (page, pageSize) => {
  pageRef.value = page
  getData()
}

const setActive = (type) => {
  activeButton.value = type
  router.push({
    query: {
      ...route.query,
      type: type === 'instant' ? null : type
    }
  })
}

// 5. Setup watchers and lifecycle hooks
watch(
  () => route.query.type,
  (newType) => {
    type.value = newType || null
    getData()
  },
  { immediate: true }
)

onMounted(() => {
  type.value = route.query.type || null
  getData()
})
</script>
<style lang="scss" scoped>
.top {
  min-height: 150px;
}

.score-container {
  height: 100%;
  width: 100vw;
  overflow: hidden;

  min-width: 1100px;
  background: $live-index-bg;

  .score-inner-container {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    overflow: scroll;

    .live-wrapper {
      min-width: 1100px;
      max-width: 1400px;
      margin: 0 auto;
    }
  }
}

.list {
  .tab {
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;

    .button {
      width: 64px;
      /* 根据需要调整按钮大小 */
      height: 36px;
      /* 根据需要调整按钮大小 */
      border: none;
      border-radius: 20px;
      cursor: pointer;
      background: url('@assets/icons/score_status_btn.webp') no-repeat;
      background-size: 100% 100%;
      margin-right: 20px;
      font-weight: 500;
      font-size: 14px;
      color: $live-index-text;
      box-shadow: 0px 0px 10px $live-index-button-shadow;
      border-radius: 20px 20px 20px 20px;
    }

    .button.active {
      background: url('@assets/icons/score_status_btn_active.webp') no-repeat;
      box-shadow: 0px 0px 10px $live-index-button-active-shadow;
      background-size: 100% 100%;
      color: $live-index-button-text-active;
      /* 在需要时添加额外的选中样式 */
    }
  }

  .match-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    min-height: calc(100vh - 100px);

    align-content: start;
  }

  .match-card {
    width: 264px;
    height: 226px;
    border-radius: 24px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    background: url('@assets/icons/home_live_item_bg.webp') no-repeat;
    background-size: 100% 100%;
    border: 1px solid transparent;

    &:hover {
      border-radius: 24px;
      box-shadow: 0 0 10px $live-index-hover-shadow;
      border: 1px solid $live-index-hover-shadow;
    }
  }

  .match-image {
    width: 100%;
    height: 150px;

    .cover {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }
  }

  .match-info {
    padding: 10px;
    color: $live-index-text;

    h3 {
      margin: 0 0 5px;
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .streamer-info {
    display: flex;
    align-items: center;
    font-size: 14px;

    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 5px;
    }

    .views {
      margin-left: auto;
      color: $live-index-hot;
    }
  }
}

.pagination {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
