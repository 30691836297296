<template>
  <div class="technical-stats" v-if="statsData.length > 0">
    <div class="stats-container">
      <div class="stats-column">
        <div v-for="(stat, index) in leftColumnStats" :key="index" class="stat-item">
          <div class="team-values">
            <span class="value">{{ stat.leftValue }}</span>
            <span class="stat-name">{{ stat.name }}</span>
            <span class="value">{{ stat.rightValue }}</span>
          </div>
          <div class="progress-bars">
            <div class="left-bar">
              <div class="progress" :style="getProgressStyle(stat, 'left')"></div>
            </div>
            <div class="right-bar">
              <div class="progress" :style="getProgressStyle(stat, 'right')"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="divider"></div>

      <div class="stats-column">
        <div v-for="(stat, index) in rightColumnStats" :key="index" class="stat-item">
          <div class="team-values">
            <span class="value">{{ stat.leftValue }}</span>
            <span class="stat-name">{{ stat.name }}</span>
            <span class="value">{{ stat.rightValue }}</span>
          </div>
          <div class="progress-bars">
            <div class="left-bar">
              <div class="progress" :style="getProgressStyle(stat, 'left')"></div>
            </div>
            <div class="right-bar">
              <div class="progress" :style="getProgressStyle(stat, 'right')"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="empty" v-else>
    <img src="@assets/imgs/empty.webp" class="empty-icons" />
    <p>暂无数据</p>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue'
import { getBasketballStats } from '@/api/match'
import { useRoute } from 'vue-router'

const route = useRoute()
const statsData = ref([])
let timer = null

// 定时器函数
const fetchStats = async () => {
  try {
    const matchId = route.query.id
    const res = await getBasketballStats({ matchId })
    if (res.data?.tech) {
      const home = res.data.tech.homeTeamStat || {}
      const away = res.data.tech.awayTeamStat || {}
      statsData.value = [
        { name: '投篮', leftValue: home.fieldGoalAttempted || 0, rightValue: away.fieldGoalAttempted || 0 },
        { name: '投篮命中', leftValue: home.fieldGoalMade || 0, rightValue: away.fieldGoalMade || 0 },  // 修改这里
        { name: '两分', leftValue: home.twoPointAttempted || 0, rightValue: away.twoPointAttempted || 0 },  // 修改这里
        { name: '两分命中', leftValue: home.twoPointMade || 0, rightValue: away.twoPointMade || 0 },  // 修改这里
        { name: '三分', leftValue: home.threePointAttempted || 0, rightValue: away.threePointAttempted || 0 },  // 修改这里
        { name: '三分命中', leftValue: home.threePointMade || 0, rightValue: away.threePointMade || 0 },  // 修改这里
        { name: '罚球', leftValue: home.freeThrowAttempted || 0, rightValue: away.freeThrowAttempted || 0 },
        { name: '罚球命中', leftValue: home.freeThrowMade || 0, rightValue: away.freeThrowMade || 0 },
        { name: '最高连续得分', leftValue: home.maxConsecutivePoints || 0, rightValue: away.maxConsecutivePoints || 0 },
        { name: '最大领先优势', leftValue: home.maxLead || 0, rightValue: away.maxLead || 0 },
        { name: '篮板', leftValue: home.rebound || 0, rightValue: away.rebound || 0 },
        { name: '进攻篮板', leftValue: home.offensiveRebounds || 0, rightValue: away.offensiveRebounds || 0 },
        { name: '防守篮板', leftValue: home.defensiveRebounds || 0, rightValue: away.defensiveRebounds || 0 },
        { name: '助攻', leftValue: home.assist || 0, rightValue: away.assist || 0 },
        { name: '盖帽', leftValue: home.blocks || 0, rightValue: away.blocks || 0 },
        { name: '抢断', leftValue: home.steals || 0, rightValue: away.steals || 0 },
        { name: '失误', leftValue: home.turnover || 0, rightValue: away.turnover || 0 },
        { name: '犯规', leftValue: home.currentPeriodFoul || 0, rightValue: away.currentPeriodFoul || 0 },
        { name: '长暂停', leftValue: home.longTimeouts || 0, rightValue: away.longTimeouts || 0 },
        { name: '剩余暂停机会', leftValue: home.currentPeriodRemainingPause || 0, rightValue: away.currentPeriodRemainingPause || 0 }
      ]
    }
  } catch (error) {
    console.error('获取技术统计数据失败:', error)
  }
}

// 组件挂载时启动定时器
onMounted(() => {
  // 立即执行一次
  fetchStats()
  // 每5秒执行一次
  timer = setInterval(fetchStats, 5000)
})

// 组件卸载时清除定时器
onUnmounted(() => {
  if (timer) {
    clearInterval(timer)
  }
})

// 计算属性
const leftColumnStats = computed(() => statsData.value.slice(0, statsData.value.length / 2))
const rightColumnStats = computed(() => statsData.value.slice(statsData.value.length / 2))

const getProgressStyle = (stat, side) => {
  const isLeft = side === 'left'
  const value = isLeft ? stat.leftValue : stat.rightValue
  const otherValue = isLeft ? stat.rightValue : stat.leftValue
  const total = value + otherValue

  // 计��百分比，避免除以0的情况
  const percentage = total === 0 ? 0 : (value / total) * 100

  return {
    width: `${percentage}%`,
    background: value >= otherValue ? '#16b13a' : '#B0B3C5',
  }
}
</script>

<style scoped lang="scss">
.technical-stats {
  padding: 60px;
}

.stats-container {
  display: flex;
  gap: 30px;
}

.stats-column {
  flex: 1;
}

.divider {
  width: 1px;
  background: $liveDetail-basketballStats-divider;
}

.stat-item {
  margin-bottom: 15px;

  .team-values {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 15px;
    color: $liveDetail-basketballStats-text;

    .stat-name {
      color: $liveDetail-basketballStats-text;
    }

    .value {
      min-width: 30px;
      color: $liveDetail-basketballStats-text;
    }
  }

  .progress-bars {
    display: flex;
    gap: 5px;

    .left-bar,
    .right-bar {
      flex: 1;
      height: 6px;
      background: $liveDetail-basketballStats-progressBg;
      border-radius: 0;
      overflow: hidden;
    }

    .left-bar .progress {
      float: right;
      height: 100%;
      transition: width 0.3s ease;
    }

    .right-bar .progress {
      float: left;
      height: 100%;
      transition: width 0.3s ease;
    }
  }
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: $liveDetail-criticalIncident-text-gray;

  .empty-icons {
    width: 300px;
    height: 300px;
  }
}
</style>