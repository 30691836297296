<template>
  <div class="right">
    <div class="live-stream">
      <div class="tab">
        <button class="live-button">直播</button>
      </div>

      <div class="ranking-tabs">
        <span class="active-tab">贡献日榜</span>
        <span>贡献周榜</span>
      </div>
      <div class="ranking-list">
        <div class="ranking-item">
          <img src="@assets/icons/live-detail/1.webp" alt="2nd" class="ranking-badge" />
          <span class="player-name">虚位以待 </span>
          <span class="points"></span>
        </div>
        <div class="ranking-item">
          <img src="@assets/icons/live-detail/2.webp" alt="2nd" class="ranking-badge" />
          <span class="player-name">虚位以待 </span>
          <span class="points"></span>
        </div>
        <div class="ranking-item">
          <img src="@assets/icons/live-detail/3.webp" alt="2nd" class="ranking-badge" />
          <span class="player-name">虚位以待 </span>
          <span class="points"></span>
        </div>
      </div>
      <div class="activity">
        <img src="@assets/icons/live-detail/speaker.webp" />
        <span class="txt">任何群、广告均为诈骗，违规者封号处理 </span>
      </div>
      <div class="message-container" ref="messageContainer">
        <div class="vip-list">
          <div class="vip-item" v-for="(res, index) in messageList" :key="res.id">
            <img
              src="@assets/icons/live-detail/chat-level0.webp"
              alt="VIP1"
              class="vip-badge"
              v-show="res.platform !== 188 || res.platform !== 199"
            />
            <img
              src="@assets/icons/live-detail/chat-platform-188.webp"
              alt="VIP1"
              class="vip-badge"
              v-show="res.platform === 188"
            />
            <img
              src="@assets/icons/live-detail/chat-platform-199.webp"
              alt="VIP1"
              class="vip-badge"
              v-show="res.platform === 199"
            />
            <div class="vip-text">
              <span
                class="nickname"
                @click="handleNicknameClick($event, res)"
                :title="userState?.userInfo?.isAnchor === 1 ? '点击管理用户' : ''"
              >
                {{ res?.nickname }}:
              </span>
              <span class="text">{{ res?.content }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="join-list">
        <span class="nickname">{{ joinList.data.nickname }}</span>
        <span class="text">{{ joinList.data.content }}</span>
      </div>
      <div class="comment-section">
        <div class="comment-input">
          <div class="isNotLogin" v-if="!isLogin">
            <span class="login_btn" @click="login">登陆</span>
            <span>参与与主播一起聊球</span>
          </div>
          <div class="isLogin" v-if="isLogin">
            <input
              type="text"
              placeholder="说点有爱的评论~"
              v-model="msg"
              @keydown.enter="sendMsg"
            />
            <button class="send-button" @click="sendMsg">发送</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="showContextMenu"
    class="context-menu"
    :style="{
      left: contextMenuPosition.x + 'px',
      top: contextMenuPosition.y + 'px'
    }"
  >
    <div class="menu-item" @click="handleBlock">拉黑用户</div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted, onUnmounted, nextTick, defineProps } from 'vue'
import { getDQRongImLibToken } from '@/api/user'
import * as RongIMLib from '@rongcloud/imlib-next'
import { useRouter } from 'vue-router'
import { AppConfigStore } from '@/stores/app'
import { userStore } from '@/stores/user'
import { message, Modal } from 'ant-design-vue'
import { textFilterAPI } from '@/api/live'
import { addBalanceAPI } from '@/api/user'

const userState = userStore()
const appConfigState = AppConfigStore()
const router = useRouter()
const isLogin = ref(false)
const msg = ref('')
const im = RongIMLib.init({ appkey: 'tdrvipksty5k5' })
const messageList = ref([])
const joinList = reactive({
  data: {}
})

const chatRoomIdRef = ref('')
const roomIdRef = ref('')
const messageContainer = ref(null)

const showContextMenu = ref(false)
const contextMenuPosition = reactive({
  x: 0,
  y: 0
})
const selectedUser = ref(null)

if (userState?.token) {
  isLogin.value = true
} else {
  isLogin.value = false
}

const initRongIMLib = () => {
  getDQRongImLibToken()
    .then((val) => {
      RongIMLib.connect(val.data.url)
        .then((res) => {
          if (res.code === RongIMLib.ErrorCode.SUCCESS) {
            console.log('链接成功, 链接用户 id 为: ', res.data.userId)
            RongIMLib.joinChatRoom(chatRoomIdRef.value, {
              count: 100
            })
              .then((res) => {
                const { code, data } = res
                if (code === RongIMLib.ErrorCode.SUCCESS) {
                  console.log(code, data)
                } else {
                  console.log(code)
                }
              })
              .catch((error) => {
                console.log(error)
              })
          } else {
            console.warn('链接失败, code:', res.code)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    })
    .catch((err) => {
      console.log(err, 'err')
    })
}

const Events = RongIMLib.Events

RongIMLib.addEventListener(Events.CONNECTING, () => {
  console.log('正在链接服务器')
})

RongIMLib.addEventListener(Events.DISCONNECT, () => {
  console.log('断开链接服务器')
})

RongIMLib.addEventListener(Events.SUSPEND, () => {
  console.log('断开链接服务器')
})

RongIMLib.addEventListener(Events.CONNECTED, () => {
  console.log('已经链接到服务器')
})

const listener = async (evt) => {
  try {
    evt.messages.forEach(async (val) => {
      if (val.content.content) {
        const data = JSON.parse(val.content.content) ? JSON.parse(val.content.content) : null
        // console.log(data, 'data')
        switch (data.type) {
          case 0:
            if (data.identity.toString() !== '3') {
              const filterResult = await textFilterAPI({
                text: data.content,
                chatId: chatRoomIdRef.value.toString()
              })
              if (filterResult.code === 200 && filterResult.data.pass === 1) {
                messageList.value.push(data)
              }
              scrollToBottom()
            }
            break
          case 1:
            joinList.data = data
            break
        }
      }
    })
  } catch (error) {
    console.log(error, 'error')
  }
}

RongIMLib.addEventListener(Events.MESSAGES, listener)

const login = async () => {
  try {
    await appConfigState.LoginModalHandle(true)
    await userState.updateUserInfo()
    isLogin.value = true
  } catch (error) {
    console.error('登录失败', error)
  }
}

const sendMsg = async () => {
  if (!userState.userInfo) {
    message.error('请先登录')
    return
  }

  if (userState.userInfo.black_id !== 0) {
    message.error('消息发送失败')
    return
  }

  if (!msg.value) {
    message.error('请输入要发送的消息')
    return
  }

  try {
    // 先进行文本过滤
    const filterResult = await textFilterAPI({
      text: msg.value,
      chatId: chatRoomIdRef.value.toString()
    })

    // 如果文本被过滤掉了，提示用户并返回
    if (filterResult.code === 200 && filterResult.data.pass === 0) {
      message.error('消息包含敏感内容，请修改后重试')
      return
    }

    const conversation = {
      conversationType: RongIMLib.ConversationType.CHATROOM,
      targetId: chatRoomIdRef.value
    }

    const data = {
      content: msg.value,
      contentColor: '',
      experienceLevel: 0,
      headUrl: undefined,
      id: '1816427620896739328',
      identity: 0,
      platform: __TEMPLATE__ === 'dtiyu' ? 188 : 199,
      isLink: 0,
      nickname: userState?.userInfo?.nick ? userState?.userInfo?.nick : 'momo',
      nobleLevel: 0,
      pushTime: null,
      sign: '',
      type: 0,
      userId: 2170106,
      violationStatus: false
    }

    const messageIm = new RongIMLib.TextMessage({
      content: JSON.stringify(data)
    })

    const res = await RongIMLib.sendMessage(conversation, messageIm)

    if (res.code === RongIMLib.ErrorCode.SUCCESS) {
      console.log('消息发送成功', res.data)
      const data = JSON.parse(res.data.content.content)
        ? JSON.parse(res.data.content.content)
        : null
      switch (data.type?.toString()) {
        case '0':
          if (data.identity !== 3) {
            messageList.value.push(data)
          }
          break
        case '1':
          joinList.data = data
          break
      }
    } else {
      console.log('消息发送失败', res.code, res.msg)
      message.error('消息发送失败，请重试')
    }

    msg.value = ''
  } catch (error) {
    console.error('发送消息出错:', error)
    message.error('发送失败，请重试')
  }
}

const listener2 = (event) => {
  if (event.rejoinedRoom) {
    console.log('SDK 内部重连聊天室信息:', event.rejoinedRoom)
  }
  if (event.updatedEntries) {
    console.log('监听到的聊天室 KV 更新:', event.updatedEntries)
  }
  if (event.userChange) {
    console.log('加入退出用户通知:', event.userChange)
  }
  if (event.chatroomDestroyed) {
    console.log('聊天室销毁:', event.chatroomDestroyed)
  }
  if (event.chatroomNotifyMultiLoginSync) {
    console.log('加入退出多端同步通知:', event.chatroomNotifyMultiLoginSync)
  }
  if (event.chatroomNotifyBlock) {
    console.log('聊天室用户封禁通知:', event.chatroomNotifyBlock)
  }
  if (event.chatroomNotifyBan) {
    console.log('聊天室用户禁言通知:', event.chatroomNotifyBan)
  }
}

RongIMLib.addEventListener(Events.CHATROOM, listener2)

const scrollToBottom = () => {
  if (messageContainer.value) {
    nextTick(() => {
      messageContainer.value.scrollTop = messageContainer.value.scrollHeight
    })
  }
}

watch(
  () => userState?.token,
  (newValue) => {
    isLogin.value = !!newValue
  }
)

onMounted(() => {
  initRongIMLib()

  document.addEventListener('click', (event) => {
    if (!event.target.closest('.context-menu') && !event.target.closest('.nickname')) {
      showContextMenu.value = false
    }
  })
})

const props = defineProps({
  chatId: {
    type: String,
    required: true
  },
  anchorId: {
    type: [String, Number],
    required: true
  }
})

watch(
  () => props.chatId,
  (newValue) => {
    chatRoomIdRef.value = newValue
    roomIdRef.value = newValue
    initRongIMLib()
  }
)

const handleNicknameClick = (event, user) => {
  // 判断当前用户是否为主播
  if (userState?.userInfo?.isAnchor !== 1) {
    return
  }

  event.preventDefault()
  showContextMenu.value = true
  contextMenuPosition.x = event.clientX
  contextMenuPosition.y = event.clientY
  selectedUser.value = user
}

const handleBlock = () => {
  Modal.confirm({
    title: '确认拉黑',
    content: `确定要拉黑用户 "${selectedUser.value.nickname}" 吗？`,
    async onOk() {
      try {
        await addBalanceAPI({ uid: selectedUser.value.userId ? parseInt(selectedUser.value.userId) : 0 })
        message.success('拉黑成功')
        showContextMenu.value = false
      } catch (error) {
        message.error('拉黑失败')
      }
    },
    onCancel() {
      showContextMenu.value = false
    }
  })
}
</script>

<style scoped lang="scss">
.right {
  width: 330px;
  height: 100%;
  background: $liveDetail-chat-primary;
  border-radius: 10px;

  .live-stream {
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    .tab {
      height: 44px;
      border-bottom: 1px solid $liveDetail-chat-border;
      display: flex;
      align-items: center;
    }

    .live-button {
      width: 80px;
      height: 28px;
      background: $liveDetail-chat-button-green;
      border-radius: 20px 20px 20px 20px;
      margin: 0 auto;
      display: block;
      border: none;
      font-weight: 400;
      font-size: 14px;
      color: $liveDetail-chat-primary;
    }

    .ranking-tabs {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 46px;

      span {
        margin: 0 10px;
        font-size: 16px;
        color: $liveDetail-chat-text-gray;
        cursor: pointer;

        &.active-tab {
          color: $liveDetail-chat-text-black;
          font-weight: bold;
        }
      }
    }

    .ranking-list {
      .ranking-item {
        display: flex;
        align-items: center;
        padding: 5px;
        background: $liveDetail-chat-ranking-bg;
        border-radius: 10px;
        margin-bottom: 5px;
        font-size: 12px;
        color: $liveDetail-chat-ranking-text;

        .ranking-badge {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          object-fit: contain;
        }

        .player-name {
          flex: 1;
        }
      }
    }

    .activity {
      background-color: $liveDetail-chat-activity-bg;
      padding: 10px;
      border-radius: 10px;
      margin: 10px 0;
      font-size: 14px;
      color: $liveDetail-chat-activity-text;
      display: flex;
      align-items: center;

      img {
        width: 20px;
      }

      .txt {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        margin-left: 5px;
      }
    }

    .message-container {
      height: 480px;
      overflow-x: hidden;
      overflow-y: scroll;

      // 隐藏滚动条
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .vip-list {
      flex: 1;
      overflow-x: hidden;
      overflow-y: scroll;

      // 隐藏滚动条
      &::-webkit-scrollbar {
        display: none;
      }

      .vip-item {
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 12px;
        display: flex;
        align-items: flex-start;

        .nickname {
          color: $liveDetail-chat-nickname;
          margin-right: 4px;
        }
        .vip-text {
          padding-top: 3px;
        }

        .vip-badge {
          width: 52px;
          margin-right: 10px;
          display: inline;
          object-fit: contain;
        }

        .vip-info {
          font-size: 12px;
          color: $liveDetail-chat-text-secondary;
          word-break: break-all;
          word-wrap: break-word;
          line-height: 20px;
        }
      }
    }

    .join-list {
      padding: 10px 12px;
      display: flex;

      .nickname {
        color: $liveDetail-chat-nickname;
        padding-right: 6px;
      }

      .text {
        color: $liveDetail-chat-join-text;
      }
    }

    .comment-section {
      .isNotLogin {
        flex: 1;
        height: 30px;
        padding: 0 10px;
        background: $liveDetail-chat-input-bg;
        box-shadow: 0px 1px 0px 0px $liveDetail-chat-input-bg;
        border-radius: 15px 15px 15px 15px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;

        .login_btn {
          margin-right: 6px;
          color: $liveDetail-chat-login-link;
          cursor: pointer;
        }
      }

      .isLogin {
        display: flex;
        align-items: center;
        flex: 1;
      }

      .comment-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 304px;
        height: 36px;
        background: $liveDetail-chat-input-bg;
        border-radius: 20px 20px 20px 20px;

        input {
          flex: 1;
          border: none;
          padding: 5px 10px;
          border-radius: 20px;
          outline: none;
          background: transparent;
        }

        .send-button {
          background-color: $liveDetail-chat-activity-text;
          color: $liveDetail-chat-primary;
          border: none;
          border-radius: 0 20px 20px 0;
          padding: 5px 15px;
          cursor: pointer;
        }
      }
    }
  }
}

.nickname {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.context-menu {
  position: fixed;
  background: white;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 4px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;

  .menu-item {
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}
</style>
