<template>
  <div class="critical-incident" v-if="!noneInfo">
    <div class="event">
      <div class="half-court" v-if="eventBall.secondHalf.length">
        <p class="title">下半场</p>
        <CriticalIncidentSlot
          :arrDataIcon="eventLegendIcon"
          :arrData="eventBall.secondHalf"
        ></CriticalIncidentSlot>
      </div>

      <div class="half-court" v-if="eventBall.firstHalf.length">
        <p class="title">上半场</p>
        <CriticalIncidentSlot
          :arrDataIcon="eventLegendIcon"
          :arrData="eventBall.firstHalf"
        ></CriticalIncidentSlot>
      </div>
    </div>

    <div class="event-legend">
      <div class="event-legend-title">
        <p>事件图例</p>
      </div>
      <div class="event-legend-icon">
        <div class="icon-info" v-for="(item, index) in eventLegendIcon" :key="index">
          <img :src="item.src" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="empty" v-else>
    <img src="@assets/imgs/empty.webp" class="empty-icons" />
    <p>暂无数据</p>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { computed, h, onMounted, reactive, ref, render } from 'vue'
import { getMatchFootballEventAPI } from '@/api/match'
import CriticalIncidentSlot from './criticalIncidentSlot.vue'

const route = useRoute()

const eventLegendIcon: any = {
  9: {key: 9, name: '进球', src: `/src/assets/${__TEMPLATE__}/icons/score/goal.webp`},
  0: {key: 0, name: '点球', src: `/src/assets/${__TEMPLATE__}/icons/score/penalty.webp`},
  7: {key: 0, name: '点球不进', src: `/src/assets/${__TEMPLATE__}/icons/score/penalty_miss.webp`},
  1: {key: 1, name: '比赛开始', src: `/src/assets/${__TEMPLATE__}/icons/score/whistle.webp`},
  34: {key: 34, name: '默认', src: `/src/assets/${__TEMPLATE__}/icons/score/default_score.webp`},
  2: {key: 0, name: '乌龙球', src: `/src/assets/${__TEMPLATE__}/icons/score/own_goal.webp`},
  30: {key: 30, name: '角球', src: `/src/assets/${__TEMPLATE__}/icons/score/corner.webp`},
  3: {key: 0, name: '助攻', src: `/src/assets/${__TEMPLATE__}/icons/score/assist.webp`},
  18: {key: 18, name: '黄牌', src: `/src/assets/${__TEMPLATE__}/icons/score/yellow_card.webp`},
  default: {key: 0, name: '红牌', src: `/src/assets/${__TEMPLATE__}/icons/score/red_card.webp`},
  21: {key: 21, name: '两黄一红', src: `/src/assets/${__TEMPLATE__}/icons/score/yellow_red_card.webp`},
  23: {key: 23, name: '换人', src: `/src/assets/${__TEMPLATE__}/icons/score/substitution.webp`},
  5: {key: 0, name: '受伤', src: `/src/assets/${__TEMPLATE__}/icons/score/injury.webp`},
  6: {key: 0, name: 'VAR', src: `/src/assets/${__TEMPLATE__}/icons/score/var.webp`},
}

const noneInfo = ref(false)

const eventBall: any = reactive({
  firstHalf: [], // 上半场
  secondHalf: [] // 下半场
})

// getMatchFootballEventAPI

const getKeyEvents = () => {
  let matchId = route.query.id
  try {
    getMatchFootballEventAPI({ matchId }).then((res) => {
      // eventId 0:暂无比赛消息  1：比赛开始  13：中场休息  3：比赛结束
      let data = res.data || []
      if ((data.length === 1 || data.length === 0) && data[0].eventId === 0) return (noneInfo.value = true)
      let dataEvent = data.filter((item: any) => ![1, 0, 3].includes(item.eventId))
      let halftimeIndex = dataEvent.findIndex((item: any) => item.eventId === 13)
      if (halftimeIndex === -1) {
        eventBall.firstHalf = [...dataEvent]
      } else {
        eventBall.secondHalf = [...dataEvent.slice(0, halftimeIndex - 1)]
        eventBall.firstHalf = [...dataEvent.slice(halftimeIndex)]
      }
    }).catch(error => {
      noneInfo.value = true
    })
  } catch (error) {
    noneInfo.value = true
  }
}

const occurTime = (time: number) => {
  return Math.round(time / 60) + "'"
}

const generateScoreHtml = (props: any) => {
  let str = props.score.split('-')
  // return `
  //                     <span class="active-score">${str[0]}</span>
  //                   <i>-</i>
  //                   <span>${str[1]}</span>
  // `
  //     <p class="score">
  // <span class="active-score">0</span>
  //     <i>-</i>
  //     <span>3</span>
  //     </p>
  // return h('p', { class: 'score' }, [h('span', str[0]), h('i', '-'), h('span', str[1])]);
  return h('p', { class: 'score' }, [
    h('span', { class: props.team === 1 ? 'active-score' : '' }, str[0]),
    h('i', ' - '),
    h('span', { class: props.team === 1 ? '' : 'active-score' }, str[1])
  ])
}

onMounted(() => {
  getKeyEvents()
})
</script>

<style scoped lang="scss">
.critical-incident {
  height: 100%;
  padding: 24px 40px;

  .img-icon {
    width: 20px;
    height: 20px;
  }

  .half-court {
    .title {
      height: 48px;
      line-height: 48px;
      border-radius: 10px;
      background-color: $liveDetail-criticalIncident-bg-light;
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      color: $liveDetail-criticalIncident-text-gray;
    }

    .tournament-info {
      min-height: 300px;
      margin: 16px 0 30px;
      border-radius: 20px;
      border: 1px solid $liveDetail-criticalIncident-border-blue;

      .incident {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 0;
        border: 1px solid $liveDetail-criticalIncident-border-blue;

        .time {
          text-align: center;
          font-weight: 500;
          font-size: 14px;
          color: $liveDetail-criticalIncident-text-gray;
          //flex-grow: 0;
          flex: 1;
        }

        .left-info,
        .right-info {
          min-width: 1px;
          height: 100%;
          flex: 4;
        }

        .right-info {
          .statistic-row,
          .goal-row {
            justify-content: left !important;
          }
        }

        .left-info .statistic-row-icon {
          margin-left: 8px;
        }

        .right-info .statistic-row-icon {
          margin-right: 8px;
        }

        .goal-row {
          display: flex;
          justify-content: flex-end;

          > div {
            display: flex;
            align-items: center;

            .score {
              width: 54px;
              height: 30px;
              margin: 0 8px;
              line-height: 30px;
              text-align: center;
              background: $liveDetail-criticalIncident-bg-light;
              border-radius: 4px;
              font-weight: 500;
              font-size: 18px;
              color: $liveDetail-criticalIncident-text-black;

              :deep(.active-score) {
                color: $liveDetail-criticalIncident-text-red;
              }
            }
          }
        }

        .statistic-row {
          display: flex;
          justify-content: right;

          > div {
            display: flex;
            align-items: center;

            div.statistic-row-name {
              p {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: $liveDetail-criticalIncident-text-dark;

                img {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  .event-legend {
    display: flex;
    align-items: center;
    height: 78px;
    margin-top: 20px;
    padding: 17px 31px;
    background: $liveDetail-criticalIncident-bg-lighter;
    border-radius: 12px;

    .event-legend-title {
      min-width: 32px;
      height: 44px;
      flex: 1;
      margin-right: 31px;

      p {
        font-weight: 500;
        font-size: 16px;
        color: $liveDetail-criticalIncident-text-dark;
        line-height: 20px;
        text-align: center;
      }
    }

    .event-legend-icon {
      //width: calc(100% - 32px);
      display: flex;
      flex-wrap: wrap;
      gap: 14px;

      .icon-info {
        width: 80px;
        display: flex;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          font-weight: 400;
          font-size: 12px;
          color: $liveDetail-criticalIncident-text-dark;
          margin-left: 4px;
        }
      }
    }
  }
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: $liveDetail-criticalIncident-text-gray;
  .empty-icons {
    width: 300px;
    height: 300px;
    // margin-bottom: 20px;
  }
}
</style>
