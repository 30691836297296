<template>
  <div class="score-ranking">
    <div class="title-row">
      <div class="title">{{ title }}</div>
      <!-- <div class="custom-select" @click="toggleDropdown">
        <span>{{ periodType === 'full' ? '全场' : '半场' }}</span>
        <div class="select-dropdown" v-if="isOpen">
          <div class="select-option" @click="selectOption('full')" :class="{ active: periodType === 'full' }">全场</div>
          <div class="select-option" @click="selectOption('half')" :class="{ active: periodType === 'half' }">半场</div>
        </div>
      </div> -->
    </div>
    <!-- 使用flex布局让两个表格在同一行 -->
    <div class="ranking-tables">
      <!-- 左侧主队表格 -->
      <div class="ranking-table">
        <div class="team-info">
          <img :src="data.homeTeam.logo" class="team-logo">
          <span class="team-name">{{ data.homeTeam.name }}</span>
        </div>
        <div class="table-content">
          <div class="table-header">
            <span class="type-column"></span>
            <span v-for="column in columns" :key="column">{{ column }}</span>
          </div>
          <div class="table-row">
            <span class="type-column">总</span>
            <span>{{ data.homeTeamRanking.allRank }}</span>
            <span>{{ data.homeTeamRanking.allWin }}/{{ data.homeTeamRanking.allLost }}</span>
            <span>{{ data.homeTeamRanking.allGoal }}</span>
            <span>{{ data.homeTeamRanking.allLostGoal }}</span>
            <span>{{ data.homeTeamRanking.allGoalDiff }}</span>
          </div>
          <div class="table-row">
            <span class="type-column">主</span>
            <span>{{ data.homeTeamRanking.hostRank || 0 }}</span>
            <span>{{ data.homeTeamRanking.hostWin }}/{{ data.homeTeamRanking.hostLost }}</span>
            <span>{{ data.homeTeamRanking.hostGoal || 0 }}</span>
            <span>{{ data.homeTeamRanking.hostLostGoal || 0 }}</span>
            <span>{{ data.homeTeamRanking.hostGoalDiff || 0 }}</span>
          </div>
          <div class="table-row">
            <span class="type-column">客</span>
            <span>{{ data.homeTeamRanking.guestRank || 0 }}</span>
            <span>{{ data.homeTeamRanking.guestWin }}/{{ data.homeTeamRanking.guestLost }}</span>
            <span>{{ data.homeTeamRanking.guestGoal || 0 }}</span>
            <span>{{ data.homeTeamRanking.guestLostGoal || 0 }}</span>
            <span>{{ data.homeTeamRanking.guestGoalDiff || 0 }}</span>
          </div>
        </div>
      </div>

      <!-- 右侧客队表格 -->
      <div class="ranking-table">
        <div class="team-info">
          <img :src="data.awayTeam.logo" class="team-logo">
          <span class="team-name">{{ data.awayTeam.name }}</span>
        </div>
        <div class="table-content">
          <div class="table-header">
            <span class="type-column"></span>
            <span v-for="column in columns" :key="column">{{ column }}</span>
          </div>
          <div class="table-row">
            <span class="type-column">总</span>
            <span>{{ data.awayTeamRanking.allRank }}</span>
            <span>{{ data.awayTeamRanking.allWin }}/{{ data.awayTeamRanking.allLost }}</span>
            <span>{{ data.awayTeamRanking.allGoal }}</span>
            <span>{{ data.awayTeamRanking.allLostGoal }}</span>
            <span>{{ data.awayTeamRanking.allGoalDiff }}</span>
          </div>
          <div class="table-row">
            <span class="type-column">主</span>
            <span>{{ data.awayTeamRanking.hostRank || 0 }}</span>
            <span>{{ data.awayTeamRanking.hostWin }}/{{ data.awayTeamRanking.hostLost }}</span>
            <span>{{ data.awayTeamRanking.hostGoal || 0 }}</span>
            <span>{{ data.awayTeamRanking.hostLostGoal || 0 }}</span>
            <span>{{ data.awayTeamRanking.hostGoalDiff || 0 }}</span>
          </div>
          <div class="table-row">
            <span class="type-column">客</span>
            <span>{{ data.awayTeamRanking.guestRank || 0 }}</span>
            <span>{{ data.awayTeamRanking.guestWin }}/{{ data.awayTeamRanking.guestLost }}</span>
            <span>{{ data.awayTeamRanking.guestGoal || 0 }}</span>
            <span>{{ data.awayTeamRanking.guestLostGoal || 0 }}</span>
            <span>{{ data.awayTeamRanking.guestGoalDiff || 0 }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

defineProps({
  title: {
    type: String,
    default: ''
  },
  columns: {
    type: Array,
    default: () => []
  },
  data: {
    type: Object,
    default: () => ({
      homeTeam: {
        logo: '',
        name: ''
      },
      awayTeam: {
        logo: '',
        name: ''
      },
      homeTeamRanking: {},
      awayTeamRanking: {}
    })
  }
})

const isOpen = ref(false)

// Close dropdown when clicking outside
onMounted(() => {
  document.addEventListener('click', (e) => {
    if (!e.target.closest('.custom-select')) {
      isOpen.value = false
    }
  })
})

// Add component name
defineOptions({
  name: 'TeamStandings'
})
</script>

<style lang="scss" scoped>
.score-ranking {
  .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid $liveDetail-standings-border;

    .title {
      font-size: 16px;
      color: $liveDetail-standings-text;
      font-weight: 500;
    }

    .custom-select {
      position: relative;
      width: 90px;
      height: 28px;
      border-radius: 20px;
      border: 1px solid $liveDetail-standings-secondary;
      color: $liveDetail-standings-secondary;
      font-size: 12px;
      padding: 0 12px;
      background-color: $liveDetail-standings-bg-white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23B1BACC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 8px center;
      background-size: 16px;
      stroke: $liveDetail-standings-secondary;

      .select-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin-top: 4px;
        background: $liveDetail-standings-bg-white;
        border-radius: 4px;
        border: 1px solid $liveDetail-standings-border;
        z-index: 10;

        .select-option {
          padding: 8px 12px;

          &:hover {
            background-color: $liveDetail-standings-bg-light;
            border-color: $liveDetail-standings-bg-light;
          }

          &.active {
            background-color: $liveDetail-standings-bg-light;
          }
        }
      }
    }
  }

  .ranking-tables {
    display: flex;
    gap: 20px;
    width: 100%;

    .ranking-table {
      flex: 1;
      background: $liveDetail-standings-bg-white;
      border-radius: 8px;
      min-width: 0;
      overflow: hidden;

      .team-info {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 15px;

        .team-logo {
          width: 24px;
          height: 24px;
          object-fit: contain;
        }

        .team-name {
          font-size: 12px;
          color: $liveDetail-standings-text;
        }
      }

      .table-content {
        border: 1px solid $liveDetail-standings-border;
        border-radius: 4px;
        margin-top: 10px;

        .table-header,
        .table-row {
          display: flex;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid $liveDetail-standings-border;

          span {
            flex: 1;
            text-align: center;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 10px 4px;
            color: $liveDetail-standings-text;
            border-right: 1px solid $liveDetail-standings-border;

            &.type-column {
              flex: 0 0 40px;
            }
          }
        }

        .table-header {
          background-color: $liveDetail-standings-bg-light;
        }

        .table-row {
          &.odd-row {
            background-color: $liveDetail-standings-bg-light;
          }

          &.even-row {
            background-color: $liveDetail-standings-bg-white;
          }
        }
      }
    }
  }
}

/* 调整排名列宽度 */
.rank-column {
  min-width: 120px;
  /* 可以根据需要调整具体宽度 */
}

/* 表格单元格文本溢出处理 */
.table-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  /* 可以根据需要调整具体宽度 */
}

/* hover 时显示完整内容 */
.table-cell:hover {
  overflow: visible;
  white-space: normal;
  position: relative;
  z-index: 1;
}

/* 可以添加一个工具提示效果 */
.table-cell[title] {
  cursor: pointer;
}

/* 表格样式 */
.table {
  width: 100%;
  /* 表格宽度100% */
  table-layout: fixed;
  /* 固定表格布局，使列宽更可控 */
}

/* 表格内容容器 */
.table-content {
  width: 100%;
  /* 内容区域宽度100% */
  height: 100%;
  /* 高度100% */
}

/* 表格单元格 */
.table-cell {
  width: 100%;
  /* 单元格宽度100% */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
  /* 可根据需要调整内边距 */
}

/* 排名列 */
.rank-column {
  min-width: 120px;
  width: 120px;
  /* 固定宽度 */
}

/* 其他列自动填充剩余空间 */
.content-column {
  width: auto;
  /* 或者设置具体的百分比，如 width: 50% */
}
</style>