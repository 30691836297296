<template>
    <div class="odds-table" v-if="tableData.length > 0">
        <div class="title">百家指数走势</div>
        <table>
            <thead>
                <tr>
                    <th>公司</th>
                    <th>主</th>
                    <th>初值</th>
                    <th>客</th>
                    <th>时间</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(data, dataIndex) in tableData" :key="dataIndex">
                    <tr v-for="(item, index) in data.coOddsWithMatchList" :key="`${dataIndex}-${index}`">
                        <td>{{ data.bookName }}</td>
                        <td>{{ item.value0ForType1 }}</td>
                        <td>{{ item.value0ForType1 > item.value0ForType2 ? '胜/半' : (item.value0ForType1 <
                            item.value0ForType2?'负/半': '平/半' ) }}</td>
                        <td>{{ item.value0ForType2 }}</td>
                        <td>{{ formatDate(item.createTime) }}</td>
                        <td :style="{ color: item.valueForType1 > item.value0ForType1 ? '#E95D5D' : '' }">{{ item.valueForType1 }}</td>
                        <td>半球</td>
                        <td :style="{ color: item.valueForType2 > item.value0ForType2 ? '#E95D5D' : '' }">{{ item.valueForType2 }}</td>
                        <td>{{ formatDate(item.updateTime) }}</td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
    <div class="empty" v-else>
        <img src="@assets/imgs/empty.webp" class="empty-icons" />
        <p>暂无数据</p>
    </div>
</template>

<script setup>
import { getBasketballOddsLive } from '@/api/match'
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

defineOptions({
    name: 'OddsTable'
})

const route = useRoute()
const tableData = ref([])

const fetchData = async () => {
    try {
        const res = await getBasketballOddsLive({ matchId: route.query.id })
        tableData.value = res.data
    } catch (error) {
        console.error('获取数据失败:', error)
    }
}

const formatDate = (timestamp) => {
    const date = new Date(timestamp)
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    return `${month}-${day} ${hours}:${minutes}`
}

onMounted(() => {
    fetchData()
})
</script>

<style lang="scss" scoped>
.odds-table {
    padding: 20px;

    .title {
        color: $liveDetail-basketballOddsLive-title;
        font-size: 16px;
        margin-bottom: 16px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid $liveDetail-basketballOddsLive-border;
        font-size: 12px;

        th,
        td {
            padding: 12px;
            text-align: center;
            border: 1px solid $liveDetail-basketballOddsLive-border;
        }

        th {
            background-color: $liveDetail-basketballOddsLive-bg-even;
            font-weight: normal;
            color: $liveDetail-basketballOddsLive-header;
        }

        td {
            color: $liveDetail-basketballOddsLive-text;
        }

        tr:nth-child(odd) {
            background-color: $liveDetail-basketballOddsLive-bg-odd;
        }

        tr:nth-child(even) {
            background-color: $liveDetail-basketballOddsLive-bg-even;
        }
    }
}
.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: $liveDetail-criticalIncident-text-gray;

  .empty-icons {
    width: 300px;
    height: 300px;
  }
}
</style>