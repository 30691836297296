import request from '@/utils/request'

export function getHomeBannerList() {
  return request.get('/api/live/PCRecommend')
}

export function getRecommendMatchList() {
  return request.post('/api/live/Recommend')
}

export function getLiveList(params: any) {
  return request.post('/api/live/PCHot', params)
}

export function getMatchCategory() {
  return request.post('/api/live/Category')
}

export function getInfoList(params: any) {
  return request.post('/api/prophecy/PCHome', params)
}

export function getMatchList(params: any) {
  return request.post('/api/match/PCHome', params)
}

export function getScheduleList(params: any) {
  return request.post('/api/match/Schedule', params)
}

// 足球积分排名
export function getFootballIntegralRankApi(params: any) {
  return request.post('api/match/FootballIntegralRank', params)
}

// 足球射手榜
export function getFootballSoccerRankApi(params: any) {
  return request.post('api/match/FootballSoccerRank', params)
}

// 篮球积分排名
export function getBasketIntegralRankApi(params: any) {
  return request.post('api/match/BasketIntegralRank', params)
}

// 篮球得分榜
export function getBasketPlayerRankApi(params: any) {
  return request.post('api/match/BasketPlayerRank', params)
}


// 安装上报
export function installAPI(params: any) {
  return request.post('/api/app/Install', params)
}

//搜索
export function searchAPI(params: any) {
  return request.post('/api/app/Search', params)
}
