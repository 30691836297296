<template>
  <div class="match-list">
    <Swiper :slides-per-view="5" :space-between="10" :slides-per-group="5">
      <SwiperSlide v-for="(match, index) in matches" :key="index">
        <div class="match-card" @click="() => goMatchDetail(match)">
          <div class="match-header">
            <span class="league-name">{{ match.leagueName }}</span>
            <span class="match-date">{{ formatTime(match.matchTime) }}</span>
          </div>
          <div class="bottom">
            <div class="match-content">
              <div class="item">
                <img :src="match.hostTeamLogo" alt="Team Logo" class="team-logo" />
                <span class="team-name">{{ match.hostTeamName }}</span>
              </div>
              <div class="item">
                <img :src="match.guestTeamLogo" alt="Team Logo" class="team-logo" />
                <span class="team-name">{{ match.guestTeamName }}</span>
              </div>
            </div>
            <button class="reserve-button">预约</button>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
    <!-- <div
      class="match-card"
      v-for="(match, index) in matches"
      :key="index"
      @click="() => goMatchDetail(match)"
    >
      <div class="match-header">
        <span>{{ match.leagueName }}</span>
        <span class="match-date">{{ formatTime(match.matchTime) }}</span>
      </div>
      <div class="bottom">
        <div class="match-content">
          <div class="item">
            <img :src="match.hostTeamLogo" alt="Team Logo" class="team-logo" />
            <span class="team-name">{{ match.hostTeamName }}</span>
          </div>
          <div class="item">
            <img :src="match.guestTeamLogo" alt="Team Logo" class="team-logo" />
            <span class="team-name">{{ match.guestTeamName }}</span>
          </div>
        </div>
        <button class="reserve-button">预约</button>
      </div>
    </div> -->
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { getRecommendMatchList } from '@/api/home'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useRouter } from 'vue-router'
import 'swiper/css'
import dayjs from 'dayjs'
const matches = ref([])
const router = useRouter()

const activeIndex = ref(0)

const setActive = (index) => {
  activeIndex.value = index
}
const goMatchDetail = (match) => {
  console.log(match, 'match')
  router.push({
    path: '/score-detail',
    query: {
      id: match.matchId,
      type: match.sportType
    }
  })
}
onMounted(() => {
  getData()
})
const getData = async () => {
  try {
    const res = await getRecommendMatchList({
      // startDate: new Date()
    })
    console.log(res, 'res')
    if (res.code === 200) {
      matches.value = res.data
    }
  } catch (error) {
    console.log(error, 'error')
  }
}
const formatTime = (num) => {
  return dayjs(num).format('YYYY-MM-DD HH:mm')
}
</script>

<style scoped lang="scss">
.match-list {
  display: flex;
  overflow-x: auto;
  gap: 16px;
  padding: 20px 0 0px;
  min-width: 1400px;
}

.match-card {
  flex: 0 0 auto;
  width: 272px;
  height: 154px;
  background: url('@assets/icons/home_live_match-bg.webp') no-repeat; // 替换为实际的背景图片路径
  background-size: 100% 100%;
  background-position: center;
  border: 2px dashed $live-match-list-border-hover;
  border-radius: 12px;

  text-align: center;
  cursor: pointer;
  &:hover {
    background: url('@assets/icons/home_live_match-active-bg.webp') no-repeat; // 替换为选中效果的背景图片路径
    background-size: 100% 100%;
  }
}

.match-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 18px 14px 10px;
  .league-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90px;
  }
}

.bottom {
  display: flex;
  align-items: flex-end;
  padding: 10px 14px;
  justify-content: space-between;
}
.match-content {
  display: flex;
  flex-direction: column;
  .item {
    display: flex;
    align-items: center;
    &:first-child {
      margin-bottom: 6px;
    }
  }
  .team-logo {
    width: 30px;
    height: 30px;
  }

  .team-name {
    margin-left: 8px;
    flex: 1;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.match-date {
  font-size: 0.875rem;
  color: $live-match-list-text-secondary;
}

.reserve-button {
  border: none;
  color: $live-match-list-color;
  border-radius: 4px;
  cursor: pointer;
  background: url('@assets/icons/home_live_btn.webp') no-repeat;
  background-size: 100% 100%;
  width: 80px;
  height: 30px;
  border-radius: 20px;
  outline: none;
}
</style>
