<template>
  <div class="dataAnalysis-battle">
    <div class="header">
      <div class="left">
        <span class="title">{{props.title}}</span>
        <div class="stats">
          <img :src=" keyIndex === '客队' ? matchData.away_team_logo : matchData.home_team_logo" class="mini-flag"/>
          <div class="subhead">
            <span class="nation">{{keyIndex === '客队' ? matchData.away_team_name : matchData.home_team_name}}</span>
            <span class="red">{{tableData.hostWinNum || '0'}}胜</span>
            <span>{{tableData.hostDrawNum || '0'}}平</span>
            <span class="blue">{{tableData.hostLoseNum || '0' }}负</span>
            <span>进{{tableData.hostScore || '0'}}球</span>
            <span>失{{tableData.guestScore || '0'}}球</span>
            <span>胜率 <i class="red">{{tableData.winRateStr || '0'}}%</i></span>
            <span>赢率<i class="red">{{tableData.asiaRateStr || '0'}}%</i></span>
            <span>大率<i class="red">{{tableData.dxRateStr || '0'}}%</i></span>
          </div>

        </div>
      </div>
      <div class="right">

        <div class="group">
          <a-radio-group v-model:value="radioGroup.value1" :options="radioGroup.options" />
        </div>
        <a-select class="select" v-model:value="matchRange" style="width: 100px">
          <a-select-option value="recent">近6场</a-select-option>
        </a-select>
      </div>
    </div>
    <a-table :columns="columns" :data-source="tableData.matches || []"
             class="default_table bout-table"
             bordered
             :row-class-name="(_record: any, index:number) => (index % 2 === 1 ? null : 'table-striped')"
             :pagination="false" size="small">
      <!--     历史交锋Table   -->
      <template #bodyCell="{text, record, index, column}">


        <template v-if="column.dataIndex === 'corner'">
          <div style="display: flex;align-items: center;justify-content: center">
            <img style="width: 10px;height: 10px;margin-right: 3px;" src="@assets/icons/score/icon-bflb-jiaoqiu@2x.webp" alt="">
            <span>{{record.hostCorner}} - {{record.guestCorner}}</span>

          </div>
        </template>

        <template v-if="column.dataIndex === 'score'">
          <span>{{record.hostTeamScore}} - {{record.guestTeamScore}}</span>
        </template>

        <template v-if="column.dataIndex === 'halfScore'">
          <span>{{record.hostTeamHalfScore}} - {{record.guestTeamHalfScore}}</span>
        </template>

        <template v-if="column.dataIndex === 'odds'">
          <span>{{record.rateDetail[1].ovalue || '-'}}</span>
        </template>

        <template v-if="column.dataIndex === 'goals'">
          <span>{{record.rateDetail[3].ovalue || '-'}}</span>
        </template>

        <template v-if="column.dataIndex === 'matchTime'">
          <span>{{convertTimestampToDate(text)}}</span>
      </template>

      </template>

    </a-table>
  </div>



</template>

<script setup lang="ts">
import {reactive, ref} from "vue";
import type {TableColumnsType} from "ant-design-vue";

const props = defineProps<{
  title: string
  keyIndex: string
  tableData: any
  columns: any
  matchData: any
}>()

const { title, tableData, columns, matchData, keyIndex } = props;

interface HistoryMatch {
  competition: string;
  date: string;
  homeTeam: string;
  score: string;
  awayTeam: string;
  halfScore: string;
  corner: string;
  result: string;
  odds: string;
  goals: number;
}
function convertTimestampToDate(timestamp) {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().substr(-2);
  return `${year}-${month}-${day}`;
}


const matchRange = ref('recent')
const radioGroup = reactive({
  value1: '1',
  value2: '1',
  options: ['同赛事', '同主客']
})

</script>

<style scoped lang="scss">
.dataAnalysis-battle {

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .left {
      display: flex;
      align-items: center;
      .subhead {
        font-size: 12px;
        .nation {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: $matchDetail-battle-text-light;
        }
        span {
          margin-right: 3px;
        }
      }
      .title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: $matchDetail-battle-text-dark;
      }
      .stats {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-left: 3px;
        font-size: 14px;
        color: $matchDetail-battle-text-gray;

        .mini-flag {
          width: 20px;
          height: 14px;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 12px;
      .group {
        :deep(.ant-radio-group) {



          .ant-radio-wrapper:hover :where(.css-dev-only-do-not-override-19iuou).ant-radio-wrapper, :where(.css-dev-only-do-not-override-19iuou).ant-radio-wrapper:hover .ant-radio-inner {
            border-color: $matchDetail-battle-radio-active;
          }

          label {
            .ant-radio-checked {
              .ant-radio-inner {


                //border-width: 2px;
                border-color: transparent;
                background-color: transparent;
                &::after {
                  //width: 100%;
                  //height: 100%;
                  background-image: url("@assets/icons/score/radio_active.png");
                  background-repeat: no-repeat;
                  background-size: 100% 100%;
                  //border: 1px solid #16B13A;
                  width: 50px!important;
                  height: 50px!important;
                  //background-color: #16B13A;
                  top: -11.3px;
                  left: -9.7px;
                }
              }

            }
          }
        }
      }
    }
  }
}

.bout-table {
  :deep(.ant-table-tbody) {

    .red-bg-cell {
      background: $matchDetail-battle-bg-stripe !important;

    }
  }
}

:deep(.ant-table) {
  table {
    border: 1px solid $matchDetail-battle-border;
  }

  .ant-table-cell {
    &::before {
      display: none;
    }
  }

  .ant-table-thead > tr > th {
    background: $matchDetail-battle-bg-white !important;
    border-bottom: 1px solid $matchDetail-battle-border-light;
    color: $matchDetail-battle-text-light !important;
    font-size: 14px !important;
    font-weight: normal !important;

  }

  .ant-table-thead > tr> th:last-of-type {
    border-right: 1px solid $matchDetail-battle-border!important;
  }
  .ant-table-thead > tr> th {
    border-inline-end: none!important;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    text-align: center;
    border-color: $matchDetail-battle-border !important;
    color: $matchDetail-battle-text-dark;
    font-size: 12px;
  }
  .table_describe {
    color: $matchDetail-battle-text-light;
  }

  .table-striped {
    background-color: $matchDetail-battle-bg-stripe;
  }


  .table-striped {
    background-color: $matchDetail-battle-bg-stripe;
  }
  .ant-table-tbody > tr:hover > td {
    background-color: $matchDetail-battle-bg-stripe !important;
  }

  .ant-table-footer {
    border: 1px solid $matchDetail-battle-border;
    border-top: none;
    background-color: $matchDetail-battle-bg-white;
    .table-footer {
      display: flex;
      align-items: center;
      padding: 0 17px;
      justify-content: space-between;
      .title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: $matchDetail-battle-text-dark;
      }
      span {
        margin-right: 3px;
      }
    }
  }


}

i {
  font-style: normal;
}
.red {
  color: $matchDetail-battle-text-red;
}

.blue {
  color: $matchDetail-battle-text-blue;
}
.default_color {
  color: $matchDetail-battle-text-light !important;
}
</style>
