<template>
  <div class="match-analysis" v-if="rankingData.homeTeam.name">
    <!-- 使用积分排名组件 -->
    <TeamStandings :data="rankingData" title="积分榜" :columns="columns1" />

    <!-- 历史交锋部分 -->
    <HistoryMatches :match-list="historyMatches" :stats="stats1" title="历史交锋" />
    <!-- 主队近况 -->
    <HistoryMatches :match-list="recentMatches" :stats="stats2" title="主队近况" />
    <!-- 客队近况 -->
    <HistoryMatches :match-list="awayRecentMatches" :stats="stats3" title="客队近况" />

    <!-- 赛果盘 -->
    <BasketballOddTrend :data="resultDataTotal" title="赛果盘路统计" :columns="columns2"/>
  </div>
  <div class="empty" v-else>
        <img src="@assets/imgs/empty.webp" class="empty-icons" />
        <p>暂无数据</p>
    </div>
</template>

<script setup>
import { ref, onMounted, onActivated, reactive } from 'vue'
import { useRoute } from 'vue-router'
import TeamStandings from './Standings.vue'
import HistoryMatches from './HistoryMatches.vue'
import BasketballOddTrend from './BasketballOddTrend.vue'
import {
  getBasketballScoreRank,
  getBasketballLineApi,
  getBasketballBattleHistory,
  getBasketballRecentHistory,
  getBasketballOddTrend
} from '@/api/match'
// Add component name
defineOptions({
  name: 'DataAnalysisIndex'
})

const route = useRoute()
const rankingData = reactive({
  homeTeam: {
    logo: '',
    name: '',
    id: ''
  },
  awayTeam: {
    logo: '',
    name: ''
  },
  homeTeamRanking: {},
  awayTeamRanking: {}
})
// 赛果盘路统计
const resultDataTotal = ref({
  homeTeam: {
    logo: '',
    name: ''
  },
  awayTeam: {
    logo: '',
    name: ''
  },
  homeTeamRanking: {},
  awayTeamRanking: {}
})
// 积分榜columns
const columns1 = ref([
  '排名',
  '胜负',
  '场均得分',
  '场均失分',
  '净胜',
])
// 赛果盘路统计columns
const columns2 = ref([
  '赢',
  '输',
  '赢率',
  '大',
  '小',
  '大率'
])
// 获取数据的方法
const fetchData = async () => {
  try {
    // 并发请求三个接口
    const [lineRes, rankRes, historyRes] = await Promise.all([
      getBasketballLineApi({ matchId: route.query.id }),
      getBasketballScoreRank({ matchId: route.query.id }),
      getBasketballBattleHistory({ matchId: route.query.id })
    ])

    if (lineRes.code === 200) {
      rankingData.homeTeam = {
        logo: lineRes.data.hostTeamLogo,
        name: lineRes.data.hostTeamName
      }
      rankingData.awayTeam = {
        logo: lineRes.data.guestTeamLogo,
        name: lineRes.data.guestTeamName
      }
      rankingData.homeTeamRanking = rankRes.data[lineRes.data.hostTeamId]
      rankingData.awayTeamRanking = rankRes.data[lineRes.data.guestTeamId]
      const recentRes = await getBasketballRecentHistory({
        matchId: route.query.id,
        hostTeamId: (lineRes.data.hostTeamId).toString(),
        guestTeamId: (lineRes.data.guestTeamId).toString()
      })
      const oddTrendRes = await getBasketballOddTrend({
        matchId: route.query.id,
        hostTeamId: (lineRes.data.hostTeamId).toString(),
        guestTeamId: (lineRes.data.guestTeamId).toString()
      })
      if (recentRes.code === 200) {
        recentMatches.value = recentRes.data.host.matches
        awayRecentMatches.value = recentRes.data.guest.matches
        stats2.value = [
          rankingData.homeTeam.logo,
          rankingData.homeTeam.name,
          recentRes.data.host.hostWinNum,
          recentRes.data.host.overNum,
          recentRes.data.host.underNum,
          recentRes.data.host.hostWinRate,
          recentRes.data.host.asiaRateStr,
          recentRes.data.host.dxRateStr,
        ]
        stats3.value = [
          rankingData.awayTeam.logo,
          rankingData.awayTeam.name,
          recentRes.data.guest.hostWinNum,
          recentRes.data.guest.overNum,
          recentRes.data.guest.underNum,
          recentRes.data.guest.guestWinRate,
          recentRes.data.guest.asiaRateStr,
          recentRes.data.guest.dxRateStr,
        ]
      }
      if (oddTrendRes.code === 200) {
        resultDataTotal.value.homeTeam = {
          logo: lineRes.data.hostTeamLogo,
          name: lineRes.data.hostTeamName
        }
        resultDataTotal.value.awayTeam = {
          logo: lineRes.data.guestTeamLogo,
          name: lineRes.data.guestTeamName
        }
        resultDataTotal.value.homeTeamRanking = oddTrendRes.data.host
        resultDataTotal.value.awayTeamRanking = oddTrendRes.data.guest
      }
    }

    // 处理历史交锋数据
    if (historyRes.code === 200 && historyRes.data) {
      // 更新统计数据
      stats1.value = [
        rankingData.homeTeam.logo,
        rankingData.homeTeam.name,
        historyRes.data.hostWinNum,
        historyRes.data.overNum,
        historyRes.data.hostLoseNum,
        historyRes.data.winRateStr,
        historyRes.data.asiaRateStr,
        historyRes.data.dxRateStr,
      ]

      // 更新历史比赛列表
      if (Array.isArray(historyRes.data.matches)) {
        historyMatches.value = historyRes.data.matches
      }
    }
  } catch (error) {
    console.error('获取数据失败:', error)
  }
}

// 如果外层使用了 keep-alive，使用 onActivated
onActivated(() => {
  fetchData()
})

// 如果没有使用 keep-alive，使用 onMounted
onMounted(() => {
  fetchData()
})

const stats1 = ref([])
const stats2 = ref([])
const stats3 = ref([])

// 历史交锋数据
const historyMatches = ref([])
// 主队近况数据
const recentMatches = ref([])
// 客队近况数据
const awayRecentMatches = ref([])
</script>

<style lang="scss" scoped>
.match-analysis {
  padding: 20px;
}
.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: $liveDetail-criticalIncident-text-gray;

  .empty-icons {
    width: 300px;
    height: 300px;
  }
}
</style>