<template>
  <div class="horizontal-layout" v-if="state.hostTeamList.length > 0 || state.guestTeamList.length > 0">
    <!-- 左侧滚动区域 -->
    <div class="scrollable-section">
      <div class="content">
        <ul class="player-list">
          <li class="player-item">
            <img :src="state.allLineUpData.hostTeamLogo" class="player-logo" />
            <div class="player-info">
              <div class="main-info">
                <span class="player-name">{{ state.allLineUpData.hostTeamName }}</span>
                <span class="player-code"></span>
              </div>
              <div class="player-details">
                <div class="left-details">
                  <span>{{ state.allLineUpData.hostCoachName }}</span>
                </div>
                <div class="right-details">
                  <span class="player-position"></span>
                </div>
              </div>
            </div>
          </li>
          <template v-for="(player, index) in state.hostTeamList" :key="player.id">
            <li class="player-item">
              <img :src="player.picUrl" class="player-logo" />
              <div class="player-info">
                <div class="main-info">
                  <span class="player-name">{{ player.playerName }}</span>
                  <span class="player-code">{{ player.shirtNumber }}</span>
                </div>
                <div class="player-details">
                  <div class="left-details">
                    <span>{{ player.height }}</span>
                  </div>
                  <div class="right-details">
                    <span class="player-position">{{ player.positionOften }}</span>
                  </div>
                </div>
              </div>
            </li>
            <li class="substitute-tip" v-if="index === 4">
              替补
            </li>
          </template>
        </ul>
      </div>
    </div>

    <!-- 中间固定区域 -->
    <div class="fixed-section">
      <div class="data-tip">点击头像查看球员本场数据</div>
      <div class="court-container">
        <!-- 上方阵容 (Team 1) -->
        <div class="line-up" v-if="state.hostTeamList.length > 0">
          <div class="three-line">
            <div class="line-item red-cloth" v-for="index in 3" :key="index">{{ state.hostTeamList[index].shirtNumber }}
              <span>{{ state.hostTeamList[index].playerName }}</span>
            </div>
          </div>
          <div class="three-line">
            <div class="line-item red-cloth" v-for="index in 2" :key="index">{{ state.hostTeamList[index +
              2].shirtNumber
              }}
              <span>{{ state.hostTeamList[index + 2].playerName }}</span>
            </div>
          </div>
        </div>

        <!-- 下方阵容 (Team 2) -->
        <div class="line-up" v-if="state.guestTeamList.length > 0">
          <div class="three-line">
            <div class="line-item blue-cloth" v-for="index in 2" :key="index">{{
              state.guestTeamList[index].shirtNumber }}
              <span>{{ state.guestTeamList[index].playerName }}</span>
            </div>
          </div>
          <div class="three-line">
            <div class="line-item blue-cloth" v-for="index in 3" :key="index">{{ state.guestTeamList[index +
              1].shirtNumber }}
              <span>{{ state.guestTeamList[index + 1].playerName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 右侧滚动区域 -->
    <div class="scrollable-section">
      <div class="content">
        <ul class="player-list">
          <li class="player-item">
            <div class="player-info">
              <div class="main-info">
                <span class="player-name"></span>
                <span class="player-code">{{ state.allLineUpData.guestTeamName }}</span>
              </div>
              <div class="player-details">
                <div class="left-details">
                  <span></span>
                </div>
                <div class="right-details">
                  <span class="player-position">{{ state.allLineUpData.guestCoachName }}</span>
                </div>
              </div>
            </div>
            <img :src="state.allLineUpData.guestTeamLogo" class="player-logo" />
          </li>
          <template v-for="(player, index) in state.guestTeamList" :key="player.id">
            <li class="player-item">
              <div class="player-info">
                <div class="main-info">
                  <span class="player-name">{{ player.shirtNumber }}</span>
                  <span class="player-code">{{ player.playerName }}</span>
                </div>
                <div class="player-details">
                  <div class="left-details">
                    <span>{{ player.positionOften }}</span>
                  </div>
                  <div class="right-details">
                    <span class="player-position">{{ player.height }}</span>
                  </div>
                </div>
              </div>
              <img :src="player.picUrl" class="player-logo" />
            </li>
            <li class="substitute-tip" v-if="index === 4">
              替补
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
  <div class="empty" v-else>
    <img src="@assets/imgs/empty.webp" class="empty-icons" />
    <p>暂无数据</p>
  </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { getBasketballLineApi } from '@/api/match'
const route = useRoute()
const state = reactive({
  hostTeamList: [], // 主队球员列表
  guestTeamList: [], // 客队球员列表
  allLineUpData: {} // 所有阵容数据
})

const getLineUpData = async () => {
  try {
    const { data } = await getBasketballLineApi({ matchId: route.query.id })
    if (data) {
      state.allLineUpData = data
      // 分别赋值主队和客队的球员列表
      state.hostTeamList = data.hostTeamMatchLineupList || []
      state.guestTeamList = data.guestTeamMatchLineupList || []
    }
  } catch (error) {
    console.log(error)
  }
}
onMounted(() => {
  getLineUpData()
})
</script>
<style lang="scss" scoped>
.horizontal-layout {
  display: flex;
  width: 100%;
  height: 100vh;
}

.scrollable-section {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
}

.scrollable-section::-webkit-scrollbar {
  width: 4px;
}

.scrollable-section::-webkit-scrollbar-track {
  background: $liveDetail-basketballLineup-scrollbarTrack;
  border-radius: 2px;
}

.scrollable-section::-webkit-scrollbar-thumb {
  background: $liveDetail-basketballLineup-scrollbarThumb;
  border-radius: 2px;
}

.scrollable-section::-webkit-scrollbar-thumb:hover {
  background: $liveDetail-basketballLineup-scrollbarThumbHover;
}

.fixed-section {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 3px;
}

.content {
  padding: 20px;
}

.data-tip {
  height: 42px;
  background-color: $liveDetail-basketballLineup-dataTipBg;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-bottom: 20px;
  color: $liveDetail-basketballLineup-dataTipText;
  font-size: 14px;
  width: 100%;
}

.player-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.player-item {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid $liveDetail-basketballLineup-borderColor;
}

.player-item:last-child {
  border-bottom: none;
}

.player-logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.player-info {
  flex: 1;
}

.main-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.player-name,
.player-code {
  font-size: 14px;
  font-weight: 500;
  color: $liveDetail-basketballLineup-playerName;
}

.player-details {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  align-items: center;
}

.right-details {
  text-align: right;
  display: flex;
  align-items: center;
}

.left-details {
  display: flex;
  align-items: center;
}

.player-position,
.left-details {
  font-size: 12px;
  color: $liveDetail-basketballLineup-secondaryText;
}

.scrollable-section:last-child .player-logo {
  margin-right: 0;
  margin-left: 10px;
}

.court-container {
  width: 100%;
  height: 600px;
  background-image: url('@assets/imgs/liveBasketball/basketball_court.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 130px;
}

.line-up {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 10px;
}

.line-item {
  position: relative;
  /* 添加相对定位 */
  width: 28px;
  height: 28px;
  text-align: center;
  color: $liveDetail-basketballLineup-white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* 添加水平居中 */
}

.line-item span {
  position: absolute;
  /* 绝对定位 */
  top: 100%;
  /* 位于号码下方 */
  left: 50%;
  /* 水平居中 */
  transform: translateX(-50%);
  /* 水平居中偏移 */
  font-size: 12px;
  color: #000;
  margin-top: 5px;
  width: max-content;
  /* 根据内容自适应宽度 */
  max-width: 60px;
  /* 最大宽度限制 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.three-line {
  width: 100%;
  display: flex;
  gap: 50px;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.red-cloth {
  background-image: url('@assets/imgs/liveBasketball/red_cloth.png');
}

.blue-cloth {
  background-image: url('@assets/imgs/liveBasketball/blue_cloth.png');
}

.substitute-tip {
  width: 100%;
  height: 56px;
  line-height: 56px;
  text-align: center;
  color: $liveDetail-basketballLineup-secondaryText;
  font-size: 14px;
  border-bottom: 1px solid $liveDetail-basketballLineup-borderColor;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: $liveDetail-criticalIncident-text-gray;

  .empty-icons {
    width: 300px;
    height: 300px;
    // margin-bottom: 20px;
  }
}
</style>