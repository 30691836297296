<template>
  <div class="text-live" v-if="events?.length">
    <div class="timeline">
      <div class="timeline-item" v-for="(event, index) in events" :key="index">
        <div class="event-content">
          <span class="time">{{ occurTime(event.occurTime)  }}</span>
          <div class="content">

            <div class="icons">
              <img
                  :src="imgIcon(event.typeId)"
                  class="event-icon"
                  alt="event icon"
              />
            </div>

            <span class="content-text">{{ event.content }}</span>

          </div>
        </div>
      </div>
    </div>
    <div class="event-legend">
      <div class="event-legend-title">
        <p>事件图例</p>
      </div>
      <div class="event-legend-icon">
        <div class="icon-info" v-for="(item, index) in eventLegendIcon" :key="index">
          <img :src="item.src" alt="">
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="empty" v-else>
    <img src="@assets/imgs/empty.webp" class="empty-icons" />
    <p>暂无数据</p>
  </div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from 'vue'
import {getMatchFootballTextDetail} from "@/api/match";
import {useRoute} from "vue-router";


const route = useRoute();

interface Event {
  occurTime: number
  content: string
  typeId: any
}

const eventLegendIcon:any = {
  9: {key: 9, name: '进球', src: `/src/assets/${__TEMPLATE__}/icons/score/goal.webp`},
  0: {key: 0, name: '点球', src: `/src/assets/${__TEMPLATE__}/icons/score/penalty.webp`},
  7: {key: 0, name: '点球不进', src: `/src/assets/${__TEMPLATE__}/icons/score/penalty_miss.webp`},
  1: {key: 1, name: '比赛开始', src: `/src/assets/${__TEMPLATE__}/icons/score/whistle.webp`},
  34: {key: 34, name: '默认', src: `/src/assets/${__TEMPLATE__}/icons/score/default_score.webp`},
  2: {key: 0, name: '乌龙球', src: `/src/assets/${__TEMPLATE__}/icons/score/own_goal.webp`},
  30: {key: 30, name: '角球', src: `/src/assets/${__TEMPLATE__}/icons/score/corner.webp`},
  3: {key: 0, name: '助攻', src: `/src/assets/${__TEMPLATE__}/icons/score/assist.webp`},
  18: {key: 18, name: '黄牌', src: `/src/assets/${__TEMPLATE__}/icons/score/yellow_card.webp`},
  default: {key: 0, name: '红牌', src: `/src/assets/${__TEMPLATE__}/icons/score/red_card.webp`},
  21: {key: 21, name: '两黄一红', src: `/src/assets/${__TEMPLATE__}/icons/score/yellow_red_card.webp`},
  23: {key: 23, name: '换人', src: `/src/assets/${__TEMPLATE__}/icons/score/substitution.webp`},
  5: {key: 0, name: '受伤', src: `/src/assets/${__TEMPLATE__}/icons/score/injury.webp`},
  6: {key: 0, name: 'VAR', src: `/src/assets/${__TEMPLATE__}/icons/score/var.webp`},
}

const imgIcon = (id: number) => {
  return (eventLegendIcon[id] || eventLegendIcon[34]).src
}

const events = ref<Event[]>()

const occurTime = (time: number) => {
  return Math.round(time / 60) + '\''
}

const textLive = () => {
  let matchId = route.query.id
  getMatchFootballTextDetail({matchId}).then(res => {
    events.value = res.data.events || []
  })
}

onMounted(() => {
  textLive()
})

</script>

<style scoped lang="scss">
.text-live {
  padding: 24px 40px;


  .timeline {
    padding-left: 20px;

    & .timeline-item:nth-of-type(1){
      .content {
        border-radius: 15px 15px 0 0 !important;
      }
    }
    & .timeline-item:last-child{
      .content {
        border-radius: 0 0 15px 15px !important;
        border-bottom: 1px solid $liveDetail-textLive-border !important;
      }
    }

    .timeline-item {
      position: relative;

      .event-content {
        display: flex;
        align-items: center;

        .time {
          width: 76px;
          height: 20px;
          color: $liveDetail-textLive-time-color;
          text-align: center;
        }

        .content {
          display: flex;
          padding: 15px 20px;
          align-items: center;
          flex: 1;
          border: 1px solid $liveDetail-textLive-border;
          border-bottom: none;
          .content-text {
            flex: 1;
            font-weight: 400;
            font-size: 14px;
            color: $liveDetail-textLive-text-color;
          }

          .icons {
            display: flex;
            gap: 5px;
            vertical-align: middle;
            .event-icon {
              width: 20px;
              height: 20px;
              margin-right: 20px;
            }
          }
        }

      }
    }
  }

  .event-legend {
    display: flex;
    align-items: center;
    height: 78px;
    margin-top: 20px;
    padding: 17px 31px;
    background: $liveDetail-textLive-legend-bg;
    border-radius: 12px;

    .event-legend-title {
      min-width: 32px;
      height: 44px;
      flex: 1;
      margin-right: 31px;

      p {
        height: 100%;
        line-height: 20px;
        font-weight: 500;
        font-size: 16px;
        color: $liveDetail-textLive-text-color;
        text-align: center;
      }
    }

    .event-legend-icon {
      //width: calc(100% - 32px);
      display: flex;
      flex-wrap: wrap;
      gap: 14px;

      .icon-info {
        width: 80px;
        display: flex;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          font-weight: 400;
          font-size: 12px;
          color: $liveDetail-textLive-text-color;
          margin-left: 4px;
        }
      }
    }
  }
}
.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: $liveDetail-criticalIncident-text-gray;
  .empty-icons {
    width: 300px;
    height: 300px;
    // margin-bottom: 20px;
  }
}
</style>
