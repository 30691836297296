<template>
    <div class="player-stats" v-if="playerData.homeTeamPlayerStats.length > 0">
        <div class="team-header">
            <div class="team-info">
                <img :src="playerData.homeTeam?.logo" class="team-logo" />
                <span class="team-name">{{ playerData.homeTeam?.name }}</span>
            </div>
            <div class="score">
                <span
                    :style="{ color: playerData.awayTeam?.score < playerData.homeTeam?.score ? '#E95D5D' : '#16B13A' }">
                    {{ playerData.awayTeam?.score }}
                </span>
                <span> - </span>
                <span style="color: #525A79">{{ playerData.homeTeam?.score }}</span>
            </div>
        </div>

        <table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>球员</th>
                    <th>时间</th>
                    <th>得分</th>
                    <th>篮板</th>
                    <th>助攻</th>
                    <th>抢断</th>
                    <th>盖帽</th>
                    <th>投篮</th>
                    <th>三分</th>
                    <th>罚球</th>
                    <th>犯规</th>
                    <th>失误</th>
                    <th>前板</th>
                    <th>后板</th>
                    <th>+/-</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(player, index) in playerData.homeTeamPlayerStats" :key="index">
                    <td>{{ player.shirtNumber }}</td>
                    <td class="player-name">
                        <img :src="player.picUrl" class="player-avatar" />
                        {{ player.name }}
                    </td>
                    <td>{{ player.playTime }}</td>
                    <td>{{ player.point }}</td>
                    <td>{{ player.rebound }}</td>
                    <td>{{ player.assist }}</td>
                    <td>{{ player.steal }}</td>
                    <td>{{ player.block }}</td>
                    <td>{{ player.fieldGoalAttempted }}</td>
                    <td>{{ player.threePointAttempted }}</td>
                    <td>{{ player.freeThrowAttempted }}</td>
                    <td>{{ player.foul }}</td>
                    <td>{{ player.turnover }}</td>
                    <td>{{ player.offensiveRebound }}</td>
                    <td>{{ player.defensiveRebound }}</td>
                    <td :class="{ 'positive': player.plusMinus > 0, 'negative': player.plusMinus < 0 }">
                        {{ player.plusMinus }}
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="team-header">
            <div class="team-info">
                <img :src="playerData.awayTeam?.logo" class="team-logo" />
                <span class="team-name">{{ playerData.awayTeam?.name }}</span>
            </div>
            <div class="score">
                <span
                    :style="{ color: playerData.homeTeam?.score < playerData.awayTeam?.score ? '#E95D5D' : '#16B13A' }">
                    {{ playerData.homeTeam?.score }}
                </span>
                <span> - </span>
                <span style="color: #525A79">{{ playerData.awayTeam?.score }}</span>
            </div>
        </div>

        <table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>球员</th>
                    <th>时间</th>
                    <th>得分</th>
                    <th>篮板</th>
                    <th>助攻</th>
                    <th>抢断</th>
                    <th>盖帽</th>
                    <th>投篮</th>
                    <th>三分</th>
                    <th>罚球</th>
                    <th>犯规</th>
                    <th>失误</th>
                    <th>前板</th>
                    <th>后板</th>
                    <th>+/-</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(player, index) in playerData.awayTeamPlayerStats" :key="index">
                    <td>{{ player.shirtNumber }}</td>
                    <td class="player-name">
                        <img :src="player.picUrl" class="player-avatar" />
                        {{ player.name }}
                    </td>
                    <td>{{ player.playTime }}</td>
                    <td>{{ player.point }}</td>
                    <td>{{ player.rebound }}</td>
                    <td>{{ player.assist }}</td>
                    <td>{{ player.steal }}</td>
                    <td>{{ player.block }}</td>
                    <td>{{ player.fieldGoalAttempted }}</td>
                    <td>{{ player.threePointAttempted }}</td>
                    <td>{{ player.freeThrowAttempted }}</td>
                    <td>{{ player.foul }}</td>
                    <td>{{ player.turnover }}</td>
                    <td>{{ player.offensiveRebound }}</td>
                    <td>{{ player.defensiveRebound }}</td>
                    <td :class="{ 'positive': player.plusMinus > 0, 'negative': player.plusMinus < 0 }">
                        {{ player.plusMinus }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="empty" v-else>
        <img src="@assets/imgs/empty.webp" class="empty-icons" />
        <p>暂无数据</p>
    </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { getBasketballPlayerCount, getBasketballLineApi } from '@/api/match'
const route = useRoute()
const playerData = reactive({
    awayTeamPlayerStats: [],
    homeTeamPlayerStats: []
})

const fetchPlayerStats = async () => {
    try {
        // 这里替换成实际的 API 调用
        const res = await getBasketballPlayerCount({ matchId: route.query.id })
        playerData.awayTeamPlayerStats = res.data.awayTeamPlayerStats
        playerData.homeTeamPlayerStats = res.data.homeTeamPlayerStats
        const lineRes = await getBasketballLineApi({ matchId: route.query.id })
        playerData.homeTeam = {
            logo: lineRes.data.hostTeamLogo,
            name: lineRes.data.hostTeamName,
            score: lineRes.data.hostTeamScore
        }
        playerData.awayTeam = {
            logo: lineRes.data.guestTeamLogo,
            name: lineRes.data.guestTeamName,
            score: lineRes.data.guestTeamScore
        }
    } catch (error) {
        console.error('获取球员统计数据失败:', error)
    }
}

onMounted(() => {
    fetchPlayerStats()
})
</script>

<style lang="scss" scoped>
.player-stats {
    padding: 20px;

    .team-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 20px;
        background-color: $liveDetail-basketballPlayerStats-bg-primary;
        margin-bottom: 1px;

        .team-info {
            display: flex;
            align-items: center;
            gap: 8px;

            .team-logo {
                width: 24px;
                height: 24px;
            }

            .team-name {
                font-size: 14px;
                color: $liveDetail-basketballPlayerStats-text-primary;
            }
        }

        .score {
            font-size: 14px;
            color: $liveDetail-basketballPlayerStats-text-primary;
            font-weight: 500;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        font-size: 12px;

        th,
        td {
            padding: 12px 8px;
            text-align: center;
            border: 1px solid $liveDetail-basketballPlayerStats-border;
            color: $liveDetail-basketballPlayerStats-text-primary;
        }

        th {
            background-color: $liveDetail-basketballPlayerStats-bg-primary;
            font-weight: normal;
            color: $liveDetail-basketballPlayerStats-text-secondary;
        }

        tr:nth-child(odd) {
            background-color: $liveDetail-basketballPlayerStats-bg-alternate;
        }

        tr:nth-child(even) {
            background-color: $liveDetail-basketballPlayerStats-bg-primary;
        }
    }

    .player-name {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .player-avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }

    .total-row {
        font-weight: bold;
    }

    .positive {
        color: $liveDetail-basketballPlayerStats-positive;
    }

    .negative {
        color: $liveDetail-basketballPlayerStats-negative;
    }
}
.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: $liveDetail-criticalIncident-text-gray;

  .empty-icons {
    width: 300px;
    height: 300px;
  }
}
</style>