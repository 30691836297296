<template>
  <div class="gift-content">
    <div>
      <div class="promo-section">
        <img src="@assets/icons/live-detail/newbie.webp" alt="Newbie Gift" class="promo-icon" />
        <img src="@assets/icons/live-detail/signin.webp" alt="Sign-in Gift" class="promo-icon" />
        <img src="@assets/icons/live-detail/invite.webp" alt="Invite Gift" class="promo-icon" />
      </div>
      <div class="match-info">
        <span class="next-match-date"></span>
      </div>
    </div>
    <div>
      <div class="gift-section">
        <!-- <button class="gift-nav prev">&lt;</button> -->
        <img src="@assets/icons/live-detail/gif1.webp" alt="gif 1" class="gift-icon" />
        <img src="@assets/icons/live-detail/gif2.webp" alt="Gift 2" class="gift-icon" />
        <img src="@assets/icons/live-detail/gif3.webp" alt="Gift 3" class="gift-icon" />
        <img src="@assets/icons/live-detail/gif4.webp" alt="Gift 4" class="gift-icon" />
        <img src="@assets/icons/live-detail/gif5.webp" alt="Gift 5" class="gift-icon" />
        <img src="@assets/icons/live-detail/gif6.webp" alt="Gift 6" class="gift-icon" />
        <img src="@assets/icons/live-detail/gif7.webp" alt="Gift 7" class="gift-icon" />
        <!-- <button class="gift-nav next">&gt;</button> -->
      </div>
      <div class="balance-section">
        <span class="balance-item">我的球钻：<span class="balance-value">0.00</span></span>
        <span class="balance-item">我的球票：<span class="balance-value">0.00</span></span>
        <button class="recharge-button">充值</button>
        <button class="backpack-button">背包</button>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
.gift-content {
  display: flex;
  align-items: center;
  background-color: $liveDetail-gift-bg;
  padding: 10px 20px;
  border-radius: 10px;
  //   font-family: Arial, sans-serif;
  justify-content: space-between;
  color: $liveDetail-gift-text;

  .promo-section {
    display: flex;
    align-items: center;

    .promo-icon {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }

  .match-info {
    flex: 1;
    // margin-left: 20px;
    margin-top: 6px;

    .next-match-date {
      font-weight: 400;
      font-size: 14px;
      color: $liveDetail-gift-date;
    }
  }

  .gift-section {
    display: flex;
    align-items: center;

    .gift-nav {
      background-color: transparent;
      border: none;
      font-size: 20px;
      cursor: pointer;
    }

    .gift-icon {
      width: 40px;
      height: 40px;
      margin: 0 5px;
      border: 1px solid $liveDetail-gift-border;
      padding: 5px;
    }
  }

  .balance-section {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-top: 10px;

    .balance-item {
      margin-right: 15px;
      font-size: 14px;
      color: $liveDetail-gift-text;

      .balance-value {
        color: $liveDetail-gift-balance;
      }
    }

    .recharge-button,
    .backpack-button {
      color: $liveDetail-gift-button;
      border: none;
      width: 54px;
      height: 20px;
      background: $liveDetail-gift-recharge;
      border-radius: 20px 20px 20px 20px;
      cursor: pointer;
      font-weight: 400;
      font-size: 12px;
    }

    .backpack-button {
      background: $liveDetail-gift-backpack;
      margin-left: 10px;
    }
  }
}
</style>
