<template>
  <div class="score-history">
    <div>
      <data-analysis-statistics title="积分排名" v-if="pointsData.host && matchData" :tableData="pointsData" :columns="pointsColumns"/>
    </div>
    <div>
      <data-analysis-battle title="历史排行榜" keyIndex="主队"  v-if="historyData && matchData" :matchData="matchData" :tableData="historyData" :columns="historyColumns"/>
    </div>
    <div>
      <data-analysis-battle title="主队近况" keyIndex="主队" v-if="recenData.host && matchData" :matchData="matchData" :tableData="recenData.host" :columns="historyColumns"/>
    </div>
    <div>
      <data-analysis-battle title="客队近况" keyIndex="客队" v-if="recenData.host && matchData" :matchData="matchData" :tableData="recenData.guest" :columns="historyColumns"/>
    </div>
    <div>
      <data-analysis-statistics title="赛果盘路统计" v-if="oddTrendData && matchData" :tableData="oddTrendData" :columns="oddTrendColumns"/>
    </div>

    <div>
      <data-analysis-statistics title="进球时间统计" v-if="footballGoalTimeData.host && matchData" :tableData="footballGoalTimeData" :columns="footballGoalTimeColumns"/>
    </div>
    <div>
      <data-analysis-statistics title="失球时间统计" v-if="footballLoseGoalTimeData.host && matchData" :tableData="footballLoseGoalTimeData" :columns="footballGoalTimeColumns"/>
    </div>
    <div>
      <data-analysis-statistics title="半全场赛果统计" v-if="footballHalfFull.host && matchData" :tableData="footballHalfFull" :columns="footballHalfFullColumns"/>
    </div>
    <div>
      <data-analysis-statistics title="总进球数统计" v-if="footballGoalSumData.host && matchData" :tableData="footballGoalSumData" :columns="footballGoalSumColumns"/>
    </div>
    <div>
      <data-analysis-statistics title="技术指标统计" v-if="footballTechStatData.host && matchData" :tableData="footballTechStatData" :columns="footballTechStatColumns"/>
    </div>
    <div>
      <data-analysis-statistics title="双方未来赛程" v-if="footballFutureScheduleData.host && matchData" :tableData="footballFutureScheduleData" :columns="footballFutureScheduleColumns"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, reactive, onMounted} from 'vue'
import DataAnalysisStatistics from "@/views/match-detail/footballComponents/dataAnalysis-statistics.vue";
import DataAnalysisBattle from "@/views/match-detail/footballComponents/dataAnalysis-battle.vue";
import {
  getFootballBattleHistoryApi, getFootballFutureScheduleApi, getFootballGoalSumApi,
  getFootballGoalTimeApi,
  getFootballHalfFullApi,
  getFootballLoseGoalTimeApi,
  getFootballOddTrendApi,
  getFootballRecentApi,
  getFootballScoreRankApi, getFootballTechStatApi,
  getMatchFootballDetailAPI
} from "@/api/match";
import {useRoute} from "vue-router";
import {mockData} from "@/views/match-detail/footballComponents/eventLegendStatus";

const route = useRoute();



// 积分排名
const pointsData:any = ref(
    {
      host: {
        logo: '',
        name: '',
        data: []
      },
      guest: {
        logo: '',
        name: '',
        data: []
      }
    }
)
// 积分排名
const getPointsRankingData = () => {
  let matchId = route.query.id
  getFootballScoreRankApi({matchId, type: 1}).then(res => {

    // let data = mockData
    let data = res.data
    let host = {
      logo: matchData.value.home_team_logo,
      name: matchData.value.home_team_name,
      data: [{...data.all[0] || []}, {...data.host[0] || []}, {...data.guest[0] || []}]
    }
    let guest = {
      logo: matchData.value.away_team_logo,
      name: matchData.value.away_team_name,
      data: [{...data.all[0] || []}, {...data.host[0] || []}, {...data.guest[0] || []}]
    }

    pointsData.value.host = {...host}
    pointsData.value.guest = {...guest}

  })
}
const pointsColumns: any = [
  {title: '', dataIndex: 'index', width: 60},
  {title: '排名', dataIndex: 'teamRank', width: 60},
  {title: '胜平负', dataIndex: 'record', width: 100},
  {title: '得失', dataIndex: 'win', width: 60},
  {title: '净胜', dataIndex: 'diff', width: 60},
  {title: '积分', dataIndex: 'point', width: 60},
  {title: '胜率', dataIndex: 'rate', width: 80}
]


// 历史信息
let historyData = ref(null)
const getFootballBattleHistory = () => {
  let matchId = route.query.id
  getFootballBattleHistoryApi({matchId}).then(res => {
    historyData.value = {...res.data}
  })
}
const historyColumns: any = [
  {title: '赛事', dataIndex: 'leagueName', width: 120},
  {title: '比赛日期', dataIndex: 'matchTime', width: 100},
  {title: '主队', dataIndex: 'hostTeamName', width: 120},
  {title: '比分', dataIndex: 'score', width: 60},
  {title: '客队', dataIndex: 'guestTeamName', width: 120},
  {title: '半场', dataIndex: 'halfScore', width: 60},
  {title: '角球', dataIndex: 'corner', width: 80},
  {title: '胜负', dataIndex: 'asiaResult', width: 80},
  {
    title: '让球', dataIndex: 'odds', width: 100, customCell: () => ({
      class: 'red-bg-cell'
    })
  },
  {
    title: '总进球', dataIndex: 'goals', width: 80, customCell: () => ({
      class: 'red-bg-cell'
    })
  }
]


//主队 客队信息
const recenData:any = ref({})
const getFootballRecent = ({hostTeamId, guestTeamId}: any) => {
  let matchId = route.query.id
  getFootballRecentApi({matchId,hostTeamId, guestTeamId}).then(res => {
    recenData.value = {...res.data}
  })
}


// 球队信息
const matchData: any = ref(null)
const getFootballInfoData = () => {
  let matchId = route.query.id
  getMatchFootballDetailAPI({matchId}).then(res => {
    let data = res.data
    matchData.value = {...data}
  }).finally(() => {

    getPointsRankingData()
    getFootballBattleHistory()
    getFootballGoalTime()
    getFootballLoseGoalTime()
    getFootballHalfFull()
    getFootballGoalSum()
    getFootballTechStat()
    getFootballFutureSchedule()

    let hostTeamId = matchData.value.home_team.id
    let guestTeamId = matchData.value.guest_team.id
    getFootballRecent({hostTeamId, guestTeamId})
    getFootballOddTrend({hostTeamId, guestTeamId})
  })
}


// 赛果盘路统计
const oddTrendData: any = ref(null)
const getFootballOddTrend = ({hostTeamId, guestTeamId}: any) => {
  let matchId = route.query.id
  getFootballOddTrendApi({matchId,hostTeamId, guestTeamId}).then(res => {


    let rHost = res.data.host
    let gHost = res.data.guest
    // 主队
    let host: any = {
      logo: matchData.value.home_team_logo,
      name: matchData.value.home_team_name,
      asiaDesc: rHost.asiaDesc.split('').slice(0,6),
      dxDesc: rHost.dxDesc.split('').slice(0,6),
      data: []
    }


    host.data[0] = {
      asiaAllWin: rHost.asiaAllWin,
      asiaAllLost: rHost.asiaAllLost,
      score: (((rHost.asiaAllWin / (rHost.asiaAllWin + rHost.asiaAllLost)) || 0) * 100).toFixed(2) + '%',
      dxHostWin: rHost.dxAllWin,
      dxHostLost: rHost.dxAllLost,
      corner: (((rHost.dxAllWin / (rHost.dxAllWin + rHost.dxAllLost)) || 0) * 100).toFixed(2) + '%',
    }
    host.data[1] = {
      asiaAllWin: rHost.asiaHostWin,
      asiaAllLost: rHost.asiaHostLost,
      score: (((rHost.asiaHostWin / (rHost.asiaHostLost + rHost.asiaHostWin)) || 0) * 100).toFixed(2) + '%',
      dxHostWin: rHost.dxHostWin,
      dxHostLost: rHost.dxHostLost,
      corner: (((rHost.dxHostWin / (rHost.dxHostWin + rHost.dxHostLost)) || 0) * 100).toFixed(2) + '%',
    }
    host.data[2] = {
      asiaAllWin: rHost.asiaGuestWin,
      asiaAllLost: rHost.asiaGuestLost,
      score: (((rHost.asiaGuestWin / (rHost.asiaGuestLost + rHost.asiaGuestWin)) || 0) * 100).toFixed(2) + '%',
      dxHostWin: rHost.dxGuestWin,
      dxHostLost: rHost.dxGuestLost,
      corner: (((rHost.dxGuestWin / (rHost.dxGuestWin + rHost.dxGuestLost)) || 0) * 100).toFixed(2) + '%',
    }

    // 客队
    let guest: any = {
      logo: matchData.value.away_team_logo,
      name: matchData.value.away_team_name,
      asiaDesc: gHost.asiaDesc.split('').slice(0,6),
      dxDesc: gHost.dxDesc.split('').slice(0,6),
      data: []
    }

    guest.data[0] = {
      asiaAllWin: gHost.asiaAllWin,
      asiaAllLost: gHost.asiaAllLost,
      score: (((gHost.asiaAllWin / (gHost.asiaAllWin + gHost.asiaAllLost)) || 0) * 100).toFixed(2) + '%',
      dxHostWin: gHost.dxAllWin,
      dxHostLost: gHost.dxAllLost,
      corner: (((gHost.dxAllWin / (gHost.dxAllWin + gHost.dxAllLost)) || 0) * 100).toFixed(2) + '%',
    }
    guest.data[1] = {
      asiaAllWin: gHost.asiaHostWin,
      asiaAllLost: gHost.asiaHostLost,
      score: (((gHost.asiaHostWin / (gHost.asiaHostLost + gHost.asiaHostWin)) || 0) * 100).toFixed(2) + '%',
      dxHostWin: gHost.dxHostWin,
      dxHostLost: gHost.dxHostLost,
      corner: (((gHost.dxHostWin / (gHost.dxHostWin + gHost.dxHostLost)) ||0) * 100).toFixed(2) + '%',
    }
    guest.data[2] = {
      asiaAllWin: gHost.asiaGuestWin,
      asiaAllLost: gHost.asiaGuestLost,
      score: (((gHost.asiaGuestWin / (gHost.asiaGuestLost + gHost.asiaGuestWin)) || 0) * 100).toFixed(2) + '%',
      dxHostWin: gHost.dxGuestWin,
      dxHostLost: gHost.dxGuestLost,
      corner: (((gHost.dxGuestWin / (gHost.dxGuestWin + gHost.dxGuestLost)) || 0) * 100).toFixed(2) + '%',
    }

    oddTrendData.value = {
      host,
      guest
    }
  })
}
const oddTrendColumns: any = [
  {title: '', dataIndex: 'index', width: 60},
  {title: '赢', dataIndex: 'asiaAllWin', width: 100},
  {title: '输', dataIndex: 'asiaAllLost', width: 120},
  {title: '赢率%', dataIndex: 'score', width: 100},
  {title: '大', dataIndex: 'dxHostWin', width: 120},
  {title: '小', dataIndex: 'dxHostLost', width: 60},
  {title: '大率%', dataIndex: 'corner', width: 80},
]

// 进球时间统计
const footballGoalTimeData: any = ref({})
const getFootballGoalTime = () => {
  let matchId = route.query.id
  getFootballGoalTimeApi({matchId}).then(res => {
    let all = res.data.filter((item: any) => item.hostGuestType === 0)
    let host = res.data.filter((item: any) => item.hostGuestType === 1)
    let guest = res.data.filter((item: any) => item.hostGuestType === 2)
    let includeItem = ['range15', 'range30', 'range45', 'range60', 'range75', 'range90']

    all.map((item:any) => {
      for (let key in item) {
        item[key] = key === 'goal' ? item[key] : `${item[key]} (${ Math.floor(((item[key] / item.goal) * 100)) || 0}%)`
      }
      return item
    })
    host.map((item:any) => {
      for (let key in item) {
        item[key] = key === 'goal' ? item[key] : `${item[key]} (${ Math.floor(((item[key] / item.goal) * 100)) || 0}%)`
      }
      return item
    })
    guest .map((item:any) => {
      for (let key in item) {
        item[key] = key === 'goal' ? item[key] : `${item[key]} (${ Math.floor(((item[key] / item.goal) * 100)) || 0}%)`
      }
      return item
    })

    let hostDataTeam = {
      logo: matchData.value.home_team_logo,
      name: matchData.value.home_team_name,
      data: [{...all[0] || []}, {...host[0] || []}, {...guest[0] || []}]
    }

    let guestDataTeam = {
      logo: matchData.value.away_team_logo,
      name: matchData.value.away_team_name,
      data: [{...all[1] || []}, {...host[1] || []}, {...guest[1] || []}]
    }

    footballGoalTimeData.value.host = {...hostDataTeam}
    footballGoalTimeData.value.guest = {...guestDataTeam}

  })
}
const footballGoalTimeColumns: any = [
  {title: '', dataIndex: 'index', width: 30},
  {title: '0-15', dataIndex: 'range15', width: 150, customCell: (record: any) => {
      let flag = setColumnStyle(record.range15)
      if (flag) {
        return {style: {'color': '#E95D5D'}}

      } else {
        return {style: {'color': '#525A79'}}

      }

    }},
  {title: '15-30', dataIndex: 'range30', width: 150, customCell: (record: any) => {
      let flag = setColumnStyle(record.range30)
      if (flag) {
        return {style: {'color': '#E95D5D'}}

      } else {
        return {style: {'color': '#525A79'}}

      }

    }},
  {title: '30-45', dataIndex: 'range45', width: 150, customCell: (record: any) => {
      let flag = setColumnStyle(record.range45)
      if (flag) {
        return {style: {'color': '#E95D5D'}}

      } else {
        return {style: {'color': '#525A79'}}

      }

    }},
  {title: '45-60', dataIndex: 'range60', width: 150, customCell: (record: any) => {
      let flag = setColumnStyle(record.range60)
      if (flag) {
        return {style: {'color': '#E95D5D'}}

      } else {
        return {style: {'color': '#525A79'}}

      }

    }},
  {title: '60-75', dataIndex: 'range75', width: 150, customCell: (record: any) => {
      let flag = setColumnStyle(record.range75)
      if (flag) {
        return {style: {'color': '#E95D5D'}}

      } else {
        return {style: {'color': '#525A79'}}

      }

    }},
  {title: '75-90+', dataIndex: 'range90', width: 200, customCell: (record: any) => {
      let flag = setColumnStyle(record.range90)
      if (flag) {
        return {style: {'color': '#E95D5D'}}

      } else {
        return {style: {'color': '#525A79'}}

      }

    }}
]


// 失球时间统计
const footballLoseGoalTimeData: any = ref({})
const getFootballLoseGoalTime = () => {
  let matchId = route.query.id
  getFootballLoseGoalTimeApi({matchId}).then(res => {

    let all = res.data.filter((item: any) => item.hostGuestType === 0)
    let host = res.data.filter((item: any) => item.hostGuestType === 1)
    let guest = res.data.filter((item: any) => item.hostGuestType === 2)

    all.map((item:any) => {
      for (let key in item) {
        item[key] = key === 'goal' ? item[key] : `${item[key]} (${ Math.floor(((item[key] / item.goal) * 100)) || 0}%)`
      }
      return item
    })
    host.map((item:any) => {
      for (let key in item) {
        item[key] = key === 'goal' ? item[key] : `${item[key]} (${ Math.floor(((item[key] / item.goal) * 100)) || 0}%)`
      }
      return item
    })
    guest .map((item:any) => {
      for (let key in item) {
        item[key] = key === 'goal' ? item[key] : `${item[key]} (${ Math.floor(((item[key] / item.goal) * 100)) || 0}%)`
      }
      return item
    })

    let hostDataTeam = {
      logo: matchData.value.home_team_logo,
      name: matchData.value.home_team_name,
      data: [{...all[0] || []}, {...host[0] || []}, {...guest[0] || []}]
    }

    let guestDataTeam = {
      logo: matchData.value.away_team_logo,
      name: matchData.value.away_team_name,
      data: [{...all[1] || []}, {...host[1] || []}, {...guest[1] || []}]
    }

    footballLoseGoalTimeData.value.host = {...hostDataTeam}
    footballLoseGoalTimeData.value.guest = {...guestDataTeam}
  })
}
const footballLoseGoalTimeColumns: any = [
  {title: '', dataIndex: 'index', width: 60},
  {title: '0-15', dataIndex: 'range15', width: 100},
  {title: '15-30', dataIndex: 'range30', width: 100},
  {title: '30-45', dataIndex: 'range45', width: 100},
  {title: '45-60', dataIndex: 'range60', width: 100},
  {title: '60-75', dataIndex: 'range75', width: 100},
  {title: '75-90+', dataIndex: 'range90', width: 100}
]


//半全场赛果统计
const footballHalfFull: any = ref({})
const getFootballHalfFull = () => {
  let matchId = route.query.id
  getFootballHalfFullApi({matchId}).then(res => {
    let all = res.data.filter((item: any) => item.hostGuestType === 0)
    let host = res.data.filter((item: any) => item.hostGuestType === 1)
    let guest = res.data.filter((item: any) => item.hostGuestType === 2)
    let hostDataTeam = {
      logo: matchData.value.home_team_logo,
      name: matchData.value.home_team_name,
      data: [{...all[0] || []}, {...host[0] || []}, {...guest[0] || []}]
    }

    let guestDataTeam = {
      logo: matchData.value.away_team_logo,
      name: matchData.value.away_team_name,
      data: [{...all[1] || []}, {...host[1] || []}, {...guest[1] || []}]
    }


    footballHalfFull.value.host = {...hostDataTeam}
    footballHalfFull.value.guest = {...guestDataTeam}
  })
}
const footballHalfFullColumns: any = [
  {title: '', dataIndex: 'index', width: 60},
  {title: '胜胜', dataIndex: 'winAndWin', width: 100},
  {title: '胜平', dataIndex: 'winAndDraw', width: 100},
  {title: '平胜', dataIndex: 'drawAndWin', width: 100},
  {title: '平平', dataIndex: 'drawAndDraw', width: 100},
  {title: '平负', dataIndex: 'drawAndLose', width: 100},
  {title: '负负', dataIndex: 'loseAndLose', width: 100},
  {title: '负平', dataIndex: 'loseAndDraw', width: 100},
  {title: '负胜', dataIndex: 'loseAndWin', width: 100}
]

//getFootballGoalSumApi 进球总数统计
const footballGoalSumData:any = ref({})
const getFootballGoalSum = () => {
  let matchId = route.query.id
  getFootballGoalSumApi({matchId}).then(res => {

    let all = res.data.filter((item: any) => item.hostGuestType === 0)
    let host = res.data.filter((item: any) => item.hostGuestType === 1)
    let guest = res.data.filter((item: any) => item.hostGuestType === 2)

    all.map((item:any) => {
      for (let key in item) {
        item[key] = `${item[key]} (${  Math.floor(((item[key] / item.goalLessOrEqual2) * 100)) || 0}%)`
      }
      return item
    })
    host.map((item:any) => {

      for (let key in item) {


        item[key] = `${item[key]} (${  Math.floor(((item[key] / item.goalLessOrEqual2) * 100)) || 0}%)`
      }

      return item
    })
    guest.map((item:any) => {
      for (let key in item) {
        item[key] = `${item[key]} (${  Math.floor(((item[key] / item.goalLessOrEqual2) * 100)) || 0}%)`
      }
      return item
    })
    let hostDataTeam = {
      logo: matchData.value.home_team_logo,
      name: matchData.value.home_team_name,
      data: [{...all[0] || []}, {...host[0] || []}, {...guest[0] || []}]
    }

    let guestDataTeam = {
      logo: matchData.value.away_team_logo,
      name: matchData.value.away_team_name,
      data: [{...all[1] || []}, {...host[1] || []}, {...guest[1] || []}]
    }


    footballGoalSumData.value.host = {...hostDataTeam}
    footballGoalSumData.value.guest = {...guestDataTeam}
  })
}
const footballGoalSumColumns: any = [
  {title: '', dataIndex: 'index', width: 60},
  {title: '0球', dataIndex: 'goal0', width: 200,customCell: (record: any) => {
      let flag = setColumnStyle(record.goal0)
      if (flag) {
        return {style: {'color': '#E95D5D'}}

      } else {
        return {style: {'color': '#525A79'}}

      }

    }},
  {title: '1球', dataIndex: 'goal1', width: 200, customCell: (record: any) => {
      let flag = setColumnStyle(record.goal1)
      if (flag) {
        return {style: {'color': '#E95D5D'}}

      } else {
        return {style: {'color': '#525A79'}}

      }

    }},
  {title: '2球', dataIndex: 'goal2', width: 200, customCell: (record: any) => {
      let flag = setColumnStyle(record.goal2)
      if (flag) {
        return {style: {'color': '#E95D5D'}}

      } else {
        return {style: {'color': '#525A79'}}

      }

    }},
  {title: '3球', dataIndex: 'goal3', width: 200, customCell: (record: any) => {
      let flag = setColumnStyle(record.goal3)
      if (flag) {
        return {style: {'color': '#E95D5D'}}

      } else {
        return {style: {'color': '#525A79'}}

      }

    }},
  {title: '4球', dataIndex: 'goal4', width: 200, customCell: (record: any) => {
      let flag = setColumnStyle(record.goal4)
      if (flag) {
        return {style: {'color': '#E95D5D'}}

      } else {
        return {style: {'color': '#525A79'}}

      }

    }},
  {title: '5球+', dataIndex: 'goal5', width: 200, customCell: (record: any) => {
      let flag = setColumnStyle(record.goal5)
      if (flag) {
        return {style: {'color': '#E95D5D'}}

      } else {
        return {style: {'color': '#525A79'}}

      }

    }}
]


//技术指标统计
const footballTechStatData:any = ref({})
const getFootballTechStat = () => {
  let matchId = route.query.id
  getFootballTechStatApi({matchId}).then(res => {
    let all = res.data.filter((item: any) => item.hostGuestType === 0)
    let host = res.data.filter((item: any) => item.hostGuestType === 1)
    let guest = res.data.filter((item: any) => item.hostGuestType === 2)
    let hostDataTeam = {
      logo: matchData.value.home_team_logo,
      name: matchData.value.home_team_name,
      data: [{...all[0] || []}, {...host[0] || []}, {...guest[0] || []}]
    }

    let guestDataTeam = {
      logo: matchData.value.away_team_logo,
      name: matchData.value.away_team_name,
      data: [{...all[1] || []}, {...host[1] || []}, {...guest[1] || []}]
    }


    footballTechStatData.value.host = {...hostDataTeam}
    footballTechStatData.value.guest = {...guestDataTeam}
  })
}
const footballTechStatColumns: any = [
  {title: '', dataIndex: 'index', width: 60},
  {title: '进球', dataIndex: 'goal', width: 200},
  {title: '角球', dataIndex: 'cornerKicks', width: 200},
  {title: '黄牌', dataIndex: 'yellow', width: 200},
  {title: '控球率', dataIndex: 'ballPossession', width: 200},
  {title: '射门', dataIndex: 'goalKicks', width: 200},
  {title: '射正', dataIndex: 'shotsOnGoal', width: 200}
]

// 双方未来赛程
const footballFutureScheduleData:any = ref({})
const getFootballFutureSchedule = () => {
  let matchId = route.query.id
  getFootballFutureScheduleApi({matchId}).then(res => {
    let data = res.data
    // let data = mockData
    let hostDataTeam = {
      logo: matchData.value.home_team_logo,
      name: matchData.value.home_team_name,
      data: data.homeTeamSchedule
    }

    let guestDataTeam = {
      logo: matchData.value.away_team_logo,
      name: matchData.value.away_team_name,
      data: data.awayTeamSchedule
    }
    footballFutureScheduleData.value.host = {...hostDataTeam}
    footballFutureScheduleData.value.guest = {...guestDataTeam}
  })
}
const footballFutureScheduleColumns: any = [
  {title: '赛事', dataIndex: 'matchName', width: 100},
  {title: '比赛日期', dataIndex: 'matchDate', width: 150},
  {title: '比赛对阵', dataIndex: 'encounter', width: 300}, // homeTeamName vs awayTeamName
  {title: '间隔', dataIndex: 'matchFullTime', width: 100}
]


const setColumnStyle = (text: string) => {
  try {
    if (!text || typeof text !== 'string') return false
    const matches = text.match(/\((\d+)%\)/)
    if (!matches || !matches[1]) return false
    return Number(matches[1]) > 20
  } catch (error) {
    return false
  }
}
const customCellFn = () => {

}


onMounted(() => {
  getFootballInfoData()

})

</script>

<style scoped lang="scss">
.score-history {
  padding: 20px;
  background: $liveDetail-statistics-bg-white;
  >div {
    margin-bottom: 24px;

  }

}
</style>
