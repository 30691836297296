<template>
  <div class="lineup-container" v-if="footballLineInfo && footballLineInfo.hostTeamMatchLineupList?.length > 0">
    <!-- 球队信息头部 -->
    <div class="team-header">
      <div class="team-info">
        <img class="team-flag" :src="footballLineInfo.hostTeamLogo || ''" alt="" />
        <span>{{footballLineInfo.hostTeamName}} (身价{{footballLineInfo.hostMarketValue}}欧元)</span>
      </div>
      <div class="team-info">
        <span>{{footballLineInfo.guestTeamName}} (身价{{footballLineInfo.guestMarketValue}}欧元)</span>
        <img class="team-flag" :src="footballLineInfo.guestTeamLogo" />
      </div>
    </div>

    <div class="field">
      <!-- 球场背景 -->
      <div class="pitch">
        <!-- 球场线条 -->

        <!-- 主队球员 -->
        <div v-if="footballLineInfo.hostTeamMatchLineupList?.length" class="team home-team">
          <div v-for="(player, index) in homeTeam" :key="index"
               class="player" :style="{ left: homeTeamPosition[player.position].x + '%', top: homeTeamPosition[player.position].y + '%' }">
            <div class="player-number">
              <span>{{player.shirtNumber}}</span>
              <img src="@assets/icons/score/image_1090@2x.webp" alt="">
            </div>
            <div class="player-name">{{ player.name }}</div>
            <div class="player-rating" v-show="player.rating">{{ player.rating }}</div>
            <div class="player-events">
              <div class="time" v-if="player.time">
                <span>{{ player.time }}</span>
                <img src="@assets/icons/score/red_bottom.webp" alt="">
              </div>
              <div class="type" v-if="eventLegendIcon[player.type]">
                <img :src="eventLegendIcon[player.type].src" alt="">
              </div>
            </div>
          </div>
        </div>

        <!-- 客队球员 -->
        <div v-if="footballLineInfo.guestTeamMatchLineupList?.length" class="team away-team">
          <div v-for="(player, index) in awayTeam" :key="index"
               class="player" :style="{ left: awayTeamPosition[player.position].x + '%', top: awayTeamPosition[player.position].y + '%' }">
            <div class="player-number">
              <span>{{player.shirtNumber}}</span>
              <img src="@assets/icons/score/image_1090@2x(1).webp" alt="">
            </div>
            <div class="player-name">{{ player.name }}</div>
            <div class="player-rating" v-show="player.rating">{{ player.rating }}</div>
            <div class="player-events">
              <div class="time" v-if="player.time">
                <span>{{ player.time }}</span>
                <img src="@assets/icons/score/red_bottom.webp" alt="">
              </div>
              <div class="type" v-if="eventLegendIcon[player.type]">
                <img :src="eventLegendIcon[player.type].src" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 教练信息 -->
      <div class="coaches">
        <div class="coach">
<!--          <a-image :width="48" :height="48" :src="footballLineInfo.hostCoachPic" fallback="/src/assets/imgs/default-head-1.webp"/>-->
          <a-image :width="48" :height="48" :src="footballLineInfo.hostCoachPic || ''" fallback="/src/assets/imgs/default-head-1.webp"/>

          <div class="coach-info">
            <div class="coach-name">主教练：{{footballLineInfo.hostCoachName}}</div>
            <div class="formation">阵型：{{footballLineInfo.hostFormation}} 胜率{{footballLineInfo.hostTeamWinRate}}%</div>
          </div>
        </div>
        <div class="coach">
          <div class="coach-info">
            <div class="coach-name">主教练：{{footballLineInfo.guestCoachName}}</div>
            <div class="formation">阵型：{{footballLineInfo.guestFormation}} 胜率{{footballLineInfo.guestTeamWinRate}}%</div>
          </div>
          <a-image :width="48" :height="48" :src="footballLineInfo.guestCoachPic || ''" fallback="/src/assets/imgs/default-head-1.webp"/>

        </div>
      </div>


<!--      首发名单 substitute:为0是首发 否则是替补 -->
      <div class="list">
        <div>
          <LineupList title="首发名单" :data="footballFirstInfo" :downData="footballSubstituteInfo"/>
        </div>
        <LineupList title="替补名单" :data="footballSubstituteInfo" :downData="footballFirstInfo  "/>
      </div>
    </div>
  </div>
  <div class="empty" v-else>
    <img src="@assets/imgs/empty.webp" class="empty-icons" />
    <p>暂无数据</p>
  </div>
</template>

<script setup lang="ts">
import LineupList from "./lineup-list.vue";
import {getFootballLineApi} from "@/api/match";
import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";

import {eventLegendIcon, occurTime} from './eventLegendStatus'

interface PlayerEvent {
  type: 'goal' | 'yellow-card' | 'red-card' | 'substitution';
}

interface Player {
  name: string;
  rating: number;
  x: number;
  y: number;
  events?: PlayerEvent[];
  distance?: string;
  positionOften?: string;
  position: number;
}

const route = useRoute();

const homeTeam: any = ref([]);

const homeTeamPosition = [
  {},
  { x: 11, y: 50, position:1 },
  { x: 22, y: 17, position:2},
  { x: 22, y: 38, position:3},
  { x: 22, y: 66, position:4},
  { x: 22, y: 84, position:5},
  { x: 31, y: 31, position:6},
  { x: 31, y: 52, position:7},
  { x: 44, y: 31, position:8},
  { x: 44, y: 52, position:9},
  { x: 31, y: 73, position:10},
  {x: 44, y: 73, position:11},
]

const awayTeamPosition = [
  {},
  {x: 89, y: 50},
  {x: 78, y: 83},
  {x: 78, y: 66},
  {x: 78, y: 37},
  {x: 78, y: 16},
  {x: 69, y: 73},
  {x: 69, y: 52},
  {x: 56, y: 73},
  {x: 56, y: 52},
  {x: 69, y: 31},
  {x: 56, y: 31},
]

const awayTeam: any = ref([])


const footballLineInfo: any = ref({})


// 替补名单
const footballSubstituteInfo: any = ref({
      hostTeamMatchLineupList: [],
      guestTeamMatchLineupList: [],
  hostEventList: [],
  guestEventList: []

    })

// 首发名单
const footballFirstInfo: any = ref({
  hostTeamMatchLineupList: [],
  guestTeamMatchLineupList: [],
  hostEventList: [],
  guestEventList: []
})


const getFootballLine = () => {
  let matchId = route.query.id
  getFootballLineApi({matchId}).then(res => {
    // footballLineInfo.value = res.data
    let data =  res.data

    footballLineInfo.value = data

    // 球场
    if (data.hostTeamMatchLineupList.length) {
      data.hostTeamMatchLineupList.forEach((item :any) => {
        if (item.position && item.position<12) {
          homeTeam.value.push({name: item.playerName, positionOften: item.positionOften, rating: item.rating, position: item.position,...item})
        }
        if (item.position<12) {
          if (item.substitute) {
            footballSubstituteInfo.value.hostTeamMatchLineupList.push({...item})
          } else {
            footballFirstInfo.value.hostTeamMatchLineupList.push({...item})
          }
        }

      })
    } else {
      homeTeam.value = []
    }

    if (data.guestTeamMatchLineupList.length) {
      data.guestTeamMatchLineupList.forEach((item :any) => {
        if (item.position && item.position<12) {
          awayTeam.value.push({name: item.playerName, positionOften: item.positionOften, rating: item.rating, position: item.position, ...item})
        }
        if (item.position<12) {
          if (item.substitute) {
            footballSubstituteInfo.value.guestTeamMatchLineupList.push({...item})
          } else {
            footballFirstInfo.value.guestTeamMatchLineupList.push({...item})
          }
        }

      })
    } else {
      awayTeam.value = []
    }


    // 球场事件
    if (data.hostEventList.length) {
      data.hostEventList.forEach((item: any) => {
        homeTeam.value.forEach((i: any) => {
          if (i.name === item.playerName && item.resetTypeName === '换人' && item.position<12) {
            i.time = occurTime(item.time)
            i.type = item.resetTypeName
          }
        })

        // 首发事件
        footballFirstInfo.value.hostTeamMatchLineupList.forEach((i: any) => {
          if (i.playerName === item.playerName && item.position<12) {
            footballFirstInfo.value.hostEventList.push({...item})
          }
        })
        //替补事件
        footballSubstituteInfo.value.hostTeamMatchLineupList.forEach((i: any) => {
          if (i.playerName === item.playerName && item.position<12) {
            footballSubstituteInfo.value.hostEventList.push({...item})
          }
        })

      })

    }

    if (data.guestEventList.length) {
      data.guestEventList.forEach((item: any) => {
        awayTeam.value.forEach((i: any) => {
          if (i.name === item.playerName && item.resetTypeName === '换人' && item.position<12) {
            i.time = occurTime(item.time)
            i.type = item.resetTypeName
          }
        })

        // 首发事件
        footballFirstInfo.value.guestTeamMatchLineupList.forEach((i: any) => {
          if (i.playerName === item.playerName && item.position<12) {
            footballFirstInfo.value.guestEventList.push({...item})
          }
        })
        //替补事件
        footballSubstituteInfo.value.guestTeamMatchLineupList.forEach((i: any) => {
          if (i.playerName === item.playerName && item.position<12) {
            footballSubstituteInfo.value.guestEventList.push({...item})
          }
        })
      })
    }
  })
}

// const isShow = computed(() => {
//   return Object.keys(footballLineInfo.value).length
// })

onMounted(() => {
  getFootballLine()
})

</script>

<style scoped lang="scss">
.lineup-container {
  width: 100%;
  padding: 20px;

  .team-header {
    display: flex;
    justify-content: space-between;

    .team-info {
      display: flex;
      align-items: center;
      gap: 8px;

      .team-flag {
        width: 24px;
        height: 16px;
      }
    }
  }

  .field {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    border: 2px solid $liveDetail-lineup-border;
    border-radius: 4px;
  }

  .pitch {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url("@assets/icons/score/soccerField.webp");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .player {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);
    z-index: 1;

    .player-number {
      position: relative;
      width: 28px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $liveDetail-lineup-text-white;
      font-weight: bold;
      img {
        width: 28px;
        height: 30px;
      }
      span {
        position: absolute;
        font-family: DIN, DIN;
        font-weight: 500;
        font-size: 12px;
        color: $liveDetail-lineup-text-white;
      }

      &.blue {
        background: $liveDetail-lineup-number-blue;
      }
    }

    .player-rating {
      width: 42px;
      height: 15px;
      line-height: 15px;
      text-align: center;
      background: $liveDetail-lineup-rating-bg;
      color: $liveDetail-lineup-text-white;
      border-radius: 12px;
      font-size: 12px;
    }

    .player-name {
      width: 60px;
      margin: 3px 0;
      font-family: DIN, DIN;
      font-weight: 500;
      font-size: 12px;
      color: $liveDetail-lineup-text-white;
      line-height: 14px;
      text-align: center;
      white-space: nowrap; /* 保持文本在一行内 */
      overflow: hidden;   /* 隐藏超出容器的文本 */
      text-overflow: ellipsis; /* 当文本被截断时显示省略号 */
    }

    .player-events {
      position: relative;
      display: flex;
      gap: 4px;
      margin-top: 4px;
      .type {
        position: absolute;
        top: -19px;
        left: 35px;
        width: 12px;
        height: 12px;
      }
      .time {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -63px;
        left: 6px;
        width: 36px;
        height: 15px;
        background: $liveDetail-lineup-text-white;
        border-radius: 10px;
        span {
          font-family: DIN, DIN;
          font-weight: 500;
          font-size: 12px;
          color: $liveDetail-lineup-text-secondary;
        }
        img {
          width: 6px;
          height: 12px;
        }
      }
    }

  }

  .coaches {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 16px;
    //background: rgba(215, 42, 42, 0.66);
    border-radius: 8px;
    .coach {
      display: flex;
      align-items: center;
      gap: 12px;

      .coach-avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        img {
          width: 48px;
          height: 48px;
        }
      }

      .coach-info {
        .coach-name {
          font-size: 14px;
          margin-bottom: 4px;
          color: $liveDetail-lineup-text-secondary;
        }
        .formation {
          font-size: 12px;
          color: $liveDetail-lineup-text-tertiary;
        }
      }
    }
  }

  .list {
    >div {
      margin-bottom: 20px;
    }
  }
}
.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: $liveDetail-criticalIncident-text-gray;
  .empty-icons {
    width: 300px;
    height: 300px;
    // margin-bottom: 20px;
  }
}
</style>
