<template>
  <div class="lineup-list" v-if="props.data.hostTeamMatchLineupList.length">
    <div class="list-header">
      <p class="title">{{ props.title }}</p>
    </div>
    <div class="players-wrapper">
      <!-- 左侧球队 -->
      <div class="team-list">
        <div v-for="player in props.data.hostTeamMatchLineupList"
             :key="player.id"
             class="player-item">
          <div class="player-info">
            <a-image :width="24" :height="24" :src="player.picUrl || ''" fallback="/src/assets/imgs/default-head-1.webp"/>

            <span class="player-number">{{ player.number }}</span>
            <div class="player-name">
              <p>
                <span style="margin-right: 6px;margin-bottom: 3px;">{{ player.playerName }}</span>
                <HEvent :playerName="player.playerName" index="hostEventList" />

              </p>
              <p class="resetTypeName" v-if="player.downTimeList.length || player.upTimeList.length">
                <img src='@assets/icons/score/substitution.webp' alt="">
                <span>{{ hostSubstitution(player, 'hostTeamMatchLineupList') }}</span>
              </p>
            </div>
          </div>

          <div class="player-status">
            <div class="player-rating" v-if="player.rating">{{ player.rating }}</div>
          </div>
        </div>
      </div>

      <!-- 右侧球队 -->
      <div class="team-list">
        <div v-for="player in props.data.guestTeamMatchLineupList"
             :key="player.id"
             class="player-item">
          <div class="player-info">
            <a-image :width="24" :height="24" :src="player.picUrl || ''"
                     fallback="/src/assets/imgs/default-head-1.webp"/>

            <span class="player-number">{{ player.number }}</span>
            <div class="player-name">
              <p>
                <span style="margin-right: 6px;margin-bottom: 3px;">{{ player.playerName }}</span>
                <HEvent :playerName="player.playerName" index="guestEventList" />
              </p>
              <p class="resetTypeName" v-if="player.downTimeList.length || player.upTimeList.length">
                <img src='@assets/icons/score/substitution.webp' alt="">
                <span>{{ hostSubstitution(player, 'guestTeamMatchLineupList') }}</span>
              </p>
            </div>
          </div>

          <div class="player-status">
            <div class="player-rating" v-if="player.rating">{{ player.rating }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, h, onMounted, defineProps} from "vue";
import {eventLegendIcon} from './eventLegendStatus'

interface PlayerEvent {
  type: string;
  icon: string;
}

interface Player {
  id: number;
  number: number;
  name: string;
  avatar: string;
  rating: string;
  events?: PlayerEvent[];
}

const props = defineProps<{
  data: any
  downData: any
  title: string
}>()

const HEvent = ({playerName = '', index = ''}) => {
  // 默认空图片
  let hData = h('img', {src: '', style: 'display: none'})

  // 检查数据是否存在
  const eventList = props.data?.[index]
  if (!eventList || !Array.isArray(eventList)) {
    return hData
  }

  // 过滤事件
  const findData = eventList.filter((item: any) => 
    item?.typeName && 
    item.typeName !== '换人' && 
    item.playerName === playerName
  )

  if (findData.length > 0 && findData[0].typeName) {
    const eventType = findData[0].typeName
    const imgInfo = eventLegendIcon[eventType]
    
    if (imgInfo?.src) {
      hData = h('img', {
        src: imgInfo.src,
        style: {
          width: '12px',
          height: '12px',
          marginLeft: '4px'
        }
      })
    }
  }

  return hData
}

const hostSubstitution = (item: any, key: string) => {
  let str = ''
  if (item.upTimeList?.length) {
    let index = item.upTimeList[0]
    let data = props.downData?.[key]
    if (Array.isArray(data)) {
      data.forEach((i: any) => {
        if (i.downTimeList?.length && i.downTimeList[0] === index) {
          str = i.playerName || ''
        }
      })
    }
  }

  if (item.downTimeList?.length) {
    let index = item.downTimeList[0]
    let data = props.downData?.[key]
    if (Array.isArray(data)) {
      data.forEach((i: any) => {
        if (i.upTimeList?.length && i.upTimeList[0] === index) {
          str = i.playerName || ''
        }
      })
    }
  }

  return str
}

onMounted(() => {
  // 可以添加一些初始化逻辑
})

</script>

<style scoped lang="scss">
.lineup-list {
  background: $liveDetail-lineupList-bg;
  border-radius: 8px;
  overflow: hidden;

  .list-header {
    padding: 12px 0;
    text-align: center;

    background-repeat: no-repeat;

    .title {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      color: $liveDetail-lineupList-title;
      position: relative;
      background-image: url("@assets/icons/score/rollCall.webp");
      background-size: 100% 100%;


      &::before {
        left: -30px;
      }

      &::after {
        right: -30px;
      }
    }
  }

  .players-wrapper {
    display: flex;
    gap: 1px;
    background: $liveDetail-lineupList-divider;
  }

  .team-list {
    flex: 1;
    background: $liveDetail-lineupList-bg;
  }

  .player-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    margin: 0 16px;
    border-bottom: 1px solid $liveDetail-lineupList-divider;

    &:last-child {
      border-bottom: none;
    }

    .player-info {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1;

      .player-avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $liveDetail-lineupList-divider;
      }

      .player-number {
        min-width: 20px;
        color: $liveDetail-lineupList-number;
        font-size: 14px;

      }

      .player-name {
        font-size: 14px;
        color: $liveDetail-lineupList-title;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        p.resetTypeName {
          span {
            color: $liveDetail-lineupList-sub !important;
          }
        }

        p {
          display: flex;
          align-items: center;

          span {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: $liveDetail-lineupList-name;
          }

          img {
            width: 12px;
            height: 12px;
            margin-right: 5px;
          }
        }
      }
    }

    .player-status {
      display: flex;
      align-items: center;
      gap: 8px;

      .player-events {
        display: flex;
        gap: 4px;

        .event-icon {
          width: 16px;
          height: 16px;
        }
      }

      .player-rating {
        min-width: 36px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background: $liveDetail-lineupList-rating-bg;
        color: $liveDetail-lineupList-rating-text;
        border-radius: 10px;
        font-size: 12px;
      }
    }
  }
}
</style>
