<template>
  <div class="techStatsModule" v-if="progressData.length > 0">
    <div class="confrontation">
      <div>
        <p style="margin-right: 10px">{{ matchData?.home_team_name || '' }}</p>
        <img :src="matchData?.home_team_logo || ''" alt="" />
      </div>
      <p class="vs">VS</p>

      <div>
        <img :src="matchData?.away_team_logo || ''" alt="" />
        <p style="margin-left: 10px">{{ matchData?.away_team_name || '' }}</p>
      </div>
    </div>

    <div class="team-skill-info">
      <div class="progress" v-for="item in progressData" :key="item.name">
        <div class="progress-item left">
          <p style="margin-right: 10px">{{ item.team1 }}</p>
          <div>
            <a-progress :showInfo="false" strokeColor="#16B13A" :percent="20" />
          </div>
        </div>
        <p>{{ item.name }}</p>
        <div>
          <div class="progress-item right">
            <div>
              <a-progress :showInfo="false" :percent="item.team2" strokeColor="#B0B3C5" />
            </div>
            <p style="margin-left: 10px">{{ item.team2 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="empty" v-else>
    <img src="@assets/imgs/empty.webp" class="empty-icons" />
    <p>暂无数据</p>
  </div>
</template>

<script setup lang="ts">
// const getMatchFootballStats
import { getMatchFootballDetailAPI, getMatchFootballStats } from '@/api/match'
import { useRoute } from 'vue-router'
import { inject, onMounted, reactive, ref } from 'vue'
import { getRecommendMatchList } from '@/api/home'

const route = useRoute()

const progressData: any = reactive({
  possession: { name: '控球率', key: 'possession', team1: 0, team2: 0 },
  goalKicks: { name: '射门', key: 'goalKicks', team1: 0, team2: 0 },
  shootOnGoal: { name: '射正', key: 'shootOnGoal', team1: 0, team2: 0 },
  shootOffGoal: { name: '射偏', key: 'shootOffGoal', team1: 0, team2: 0 },
  pass: { name: '传球', key: 'pass', team1: 0, team2: 0 },
  accuratePasses: { name: '传球成功', key: 'accuratePasses', team1: 0, team2: 0 },
  crosses: { name: '传中', key: 'crosses', team1: 0, team2: 0 },
  crossesSuccess: { name: '传中成功', key: 'crossesSuccess', team1: 0, team2: 0 },
  longBalls: { name: '长传', key: 'longBalls', team1: 0, team2: 0 },
  longBallsSuccess: { name: '长传成功', key: 'longBallsSuccess', team1: 0, team2: 0 },
  cornerKicks: { name: '角球', key: 'cornerKicks', team1: 0, team2: 0 },
  yellowCards: { name: '黄牌', key: 'yellowCards', team1: 0, team2: 0 },
  attack: { name: '进攻', key: 'attack', team1: 0, team2: 0 },
  dangerAttack: { name: '危险进攻', key: 'dangerAttack', team1: 0, team2: 0 },
  bigChances: { name: '绝佳机会', key: 'bigChances', team1: 0, team2: 0 },
  clearances: { name: '解围', key: 'clearances', team1: 0, team2: 0 },
  fouls: { name: '犯规', key: 'fouls', team1: 0, team2: 0 }
})

const getTeamSkillInfo = () => {
  let matchId = route.query.id
  getMatchFootballStats({ matchId }).then((res) => {
    // eventId 0:暂无比赛消息  1：比赛开始  13：中场休息  3：比赛结束
    let data = res.data
    let keys = Object.keys(data)

    for (let i in keys) {
      let key = keys[i]
      if (progressData[key]) {
        progressData[key].team1 = data[key].team1
        progressData[key].team2 = data[key].team2
      }
    }
    // progressData.value = {...data}
  })
}
const matchData = ref()
const getData = async () => {
  try {
    let matchId = route.query.id
    const res = await getMatchFootballDetailAPI({ matchId })
    if (res.code === 200) {
      matchData.value = { ...res.data.match_info }
    }
  } catch (error) {
    console.log(error, 'error')
  }
}

onMounted(() => {
  getTeamSkillInfo()
  getData()
})
</script>

<style scoped lang="scss">
.techStatsModule {
  .confrontation {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;

    p.vs {
      width: 42px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin: 0 30px;
      font-weight: 500;
      font-size: 16px;
      color: $liveDetail-techStatsModule-text-secondary;
    }

    >div {
      display: flex;

      img {
        width: 26px;
        height: 26px;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        color: $liveDetail-techStatsModule-text-secondary;
      }
    }
  }

  .team-skill-info {
    width: 690px;
    padding: 30px;
    margin: 18px auto 20px;
    min-height: 734px;
    border-radius: 20px;
    border: 1px solid $liveDetail-techStatsModule-border;

    .progress {
      display: flex;
      width: 630px;
      height: 24px;
      margin-bottom: 16px;
      justify-content: center;

      .progress-item {
        display: flex;
        width: 267px;

        >div {
          width: 250px;
        }

        p {
          width: 30px;
          height: 17px;
          font-weight: 400;
          font-size: 12px;
          color: $liveDetail-techStatsModule-text-light;
        }
      }

      .left {
        :deep(.ant-progress-inner) {
          display: flex;
          justify-content: flex-end;
          border-radius: 100px 0 0 100px !important;

          .ant-progress-bg {
            border-radius: 100px 0 0 100px !important;
          }
        }
      }

      .right {
        :deep(.ant-progress-inner) {
          border-radius: 0 100px 100px 0 !important;

          .ant-progress-bg {
            border-radius: 0 100px 100px 0 !important;
          }
        }
      }

      p {
        width: 96px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        color: $liveDetail-techStatsModule-text-secondary;
      }
    }
  }
}
.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: $liveDetail-criticalIncident-text-gray;
  .empty-icons {
    width: 300px;
    height: 300px;
    // margin-bottom: 20px;
  }
}
</style>
