<template>
  <div class="tabs-container">
    <template v-if="currentTabs.length">
      <div class="tabs-navigation">
        <div
          v-for="(tab, index) in currentTabs"
          :key="index"
          :class="['tab-item', { active: selectedTab === index }]"
          @click="selectTab(tab, index)"
        >
          {{ tab.name }}
        </div>
      </div>
      <div class="tabs-content">
        <component v-if="currentTabComponent" :is="currentTabComponent" :matchData="matchData" />
        <div class="empty" v-else>
          <img src="@assets/imgs/empty.webp" class="empty-icons" />
          <p>暂无数据</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, markRaw, inject, watch } from 'vue'
import { useRoute } from 'vue-router'

// 导入足球组件
import FootballTextLive from './footballComponents/textLive.vue'
import FootballLineup from './footballComponents/lineup.vue'
import FootballTechStats from './footballComponents/techStatsModule.vue'
import FootballDataAnalysis from './footballComponents/dataAnalysis.vue'
import FootballExponentLive from './footballComponents/exponentLive.vue'
import FootballCriticalIncident from './footballComponents/criticalIncident.vue'

// 导入篮球组件
import BasketballTextLive from './basketballComponents/BasketballTextLive.vue'
import BasketballLineup from './basketballComponents/BasketballLineup.vue'
import BasketballTechStats from './basketballComponents/BasketballStats.vue'
import BasketballOddsLive from './basketballComponents/BasketballOddsLive.vue'
import BasketballPlayerStats from './basketballComponents/BasketballPlayerStats.vue'
import BasketballDataAnalysis from './basketballComponents/DataAnalysis/index.vue'

const route = useRoute()
const matchData = inject('matchData')

// 定义足球和篮球的tabs配置
const footballTabs = [
  { name: '关键事件', key: markRaw(FootballCriticalIncident) },
  { name: '文字直播', key: markRaw(FootballTextLive) },
  { name: '技术统计', key: markRaw(FootballTechStats) },
  { name: '出场阵容', key: markRaw(FootballLineup) },
  { name: '数据分析', key: markRaw(FootballDataAnalysis) },
  { name: '指数直播', key: markRaw(FootballExponentLive) }
]

const basketballTabs = [
  { name: '文字直播', key: markRaw(BasketballTextLive) },
  { name: '出场阵容', key: markRaw(BasketballLineup) },
  { name: '球队统计', key: markRaw(BasketballTechStats) },
  { name: '球员统计', key: markRaw(BasketballPlayerStats) },
  { name: '数据分析', key: markRaw(BasketballDataAnalysis) },
  { name: '指数直播', key: markRaw(BasketballOddsLive) }
]

const selectedTab = ref(0)

// 根据比赛类型选择对应的tabs
const currentTabs = computed(() => {
  const sportId = matchData.value?.sportId || matchData.value.matchInfo.sport_id
  return sportId === 1 ? footballTabs : sportId === 2 ? basketballTabs : []
})

// 添加 watch 来处理 tab 重置
watch(
  () => matchData.value?.sportId,
  (newSportId) => {
    console.log(newSportId, 'newSportId监听', matchData)
    if (newSportId) {
      selectedTab.value = 0 // 切换运动类型时重置选中的tab
    }
  },
  { immediate: true }
)

const selectTab = (tab, index) => {
  selectedTab.value = index
}

const currentTabComponent = computed(() => {
  return currentTabs.value[selectedTab.value]?.key || null
})
</script>

<style scoped lang="scss">
.tabs-container {
  width: 100%;
  background: $liveDetail-tab-bg;
  border-radius: 10px;
  overflow: hidden;
}

.tabs-navigation {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: $liveDetail-tab-bg;
  padding: 10px 0;
  font-family: Arial, sans-serif;
  border-bottom: 1px solid $liveDetail-tab-border;

  .tab-item {
    font-size: 16px;
    color: $liveDetail-tab-text;
    cursor: pointer;
    padding: 20px 0;

    &.active {
      font-size: 16px;
      color: $liveDetail-tab-active;
      font-weight: bold;
      border-bottom: 2px solid $liveDetail-tab-active;
    }
  }
}

.tabs-content {
  min-height: 400px;

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;

    img {
      width: 200px;
      height: 200px;
    }

    p {
      color: $liveDetail-tab-empty-text;
      margin-top: 20px;
    }
  }
}
</style>
