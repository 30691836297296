<template>
  <div class="history-matches">
    <div class="title-wrapper">
      <h3 class="title">{{ title }}</h3>
      <div class="stats">
        <span class="team-info">
          <img :src="stats[0]" class="team-logo" alt="team logo">
          <span class="team-name">{{ stats[1] }}</span>
        </span>
        <span class="highlight">{{ stats[2] }}</span><span class="text-red">胜</span>
        {{ stats[3] }}平
        <span class="highlight-blue">{{ stats[4] }}</span><span class="text-blue">负</span>
        胜率<span class="highlight">{{ stats[5] }}</span>
        赢率<span class="highlight">{{ stats[6] }}</span>
        大率<span class="highlight">{{ stats[7] }}</span>
      </div>
    </div>
    <div class="table-content">
      <div class="table-header">
        <span>赛事</span>
        <span>比赛日期</span>
        <span>主队</span>
        <span>比分</span>
        <span>客队</span>
        <span>半场</span>
        <span>胜负</span>
        <span>分差</span>
        <span>让分</span>
        <span>总分</span>
      </div>
      <div v-for="(item, index) in matchList" :key="index" class="table-row">
        <span>{{ item.leagueName }}</span>
        <span>{{ formatDate(item.matchTime) }}</span>
        <span>{{ item.hostTeamName }}</span>
        <span class="score">{{ item.hostTeamScore }}-{{ item.guestTeamScore }}</span>
        <span>{{ item.guestTeamName }}</span>
        <span>{{ item.hostTeamHalfScore }}-{{ item.guestTeamHalfScore }}</span>
        <span>{{ item.asiaResult }}</span>
        <span>{{ item.hostTeamScore - item.guestTeamScore }}</span>
        <span>{{ item.asiaRate }}</span>
        <span>{{ item.hostTeamScore + item.guestTeamScore }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  matchList: {
    type: Array,
    default: () => []
  },
  title: {
    type: String,
    default: ''
  },
  teamLogo: {
    type: String,
    default: ''
  },
  teamName: {
    type: String,
    default: ''
  },
  stats: {
    type: Array,
    default: () => []
  }
})
const formatDate = (dateString) => {
  if (!dateString) return ''
  const date = new Date(dateString)
  const year = date.getFullYear().toString().slice(2) // 获取年份后两位
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}`
}
</script>

<style lang="scss" scoped>
.history-matches {
  margin-top: 20px;

  .title-wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 15px;
    gap: 10px;

    .title {
      margin-bottom: 0;
      font-size: 16px;
      color: $liveDetail-historyMatches-titleColor;
      font-weight: 500;
      line-height: 1;
    }

    .stats {
      font-size: 12px;
      color: $liveDetail-historyMatches-textGray;
      line-height: 1;
      display: flex;
      align-items: center;
      gap: 4px;

      .team-info {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-right: 4px;

        .team-logo {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }

        .team-name {
          color: $liveDetail-historyMatches-textGray;
        }
      }

      .highlight {
        color: $liveDetail-historyMatches-red;
      }

      .text-red {
        color: $liveDetail-historyMatches-red;
      }

      .highlight-blue {
        color: $liveDetail-historyMatches-blue;
      }

      .text-blue {
        color: $liveDetail-historyMatches-blue;
      }
    }
  }

  .table-content {
    .table-header {
      display: grid;
      grid-template-columns: 12% 12% 16% 10% 16% 10% 6% 6% 6% 6%;
      padding: 10px;
      border-bottom: 1px solid $liveDetail-historyMatches-borderGray;
      align-items: center;

      span {
        text-align: center;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 4px;
        color: $liveDetail-historyMatches-textGray;

        &:nth-child(3) {
          text-align: right;
          padding-right: 8px;
        }

        &:nth-child(4),
        &:nth-child(5) {
          text-align: center;
          padding: 0;
        }
      }
    }

    .table-row {
      display: grid;
      grid-template-columns: 12% 12% 16% 10% 16% 10% 6% 6% 6% 6%;
      padding: 10px;
      border-bottom: 1px solid $liveDetail-historyMatches-borderGray;
      align-items: center;

      span {
        text-align: center;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 4px;
        color: $liveDetail-historyMatches-titleColor;

        &.score {
          color: $liveDetail-historyMatches-red;
        }

        &:nth-child(3) {
          text-align: right;
          padding-right: 8px;
        }

        &:nth-child(4) {
          text-align: center;
          padding: 0;
        }

        &:nth-child(5) {
          text-align: left;
          padding-left: 8px;
        }
      }
    }

    .table-header span,
    .table-row span {
      &:nth-child(3) {
        text-align: right;
        padding-right: 8px;
      }

      &:nth-child(4) {
        text-align: center;
        padding: 0;
      }

      &:nth-child(5) {
        text-align: left;
        padding-left: 8px;
      }
    }
  }
}

// 统一的标题行样式
.title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $liveDetail-historyMatches-borderGray;
  padding-bottom: 10px;

  .title {
    font-size: 16px;
    color: $liveDetail-historyMatches-titleColor;
    font-weight: 500;
  }

  .period-select {
    width: 90px;
    height: 28px;
    border-radius: 20px;
    border: 1px solid $liveDetail-historyMatches-grayBorder;
    color: $liveDetail-historyMatches-grayBorder;
    font-size: 12px;
    padding: 0 12px;
    outline: none;
    background-color: $liveDetail-historyMatches-white;
    cursor: pointer;

    // 自定义下拉箭头
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23B1BACC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 16px;

    &:focus {
      border-color: $liveDetail-historyMatches-grayBorder;
    }

    option {
      color: $liveDetail-historyMatches-grayBorder;
      background-color: $liveDetail-historyMatches-white;
    }
  }
}

// 统一的表格内容样式
.table-content {
  background: $liveDetail-historyMatches-white;
  border: 1px solid $liveDetail-historyMatches-borderGray;

  .table-header,
  .table-row {
    display: grid;
    grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 10px 10px 10px 0;
    border-bottom: 1px solid $liveDetail-historyMatches-borderGray;
    align-items: center;

    span {
      text-align: center;
      font-size: 12px;

      &.type-column {
        color: $liveDetail-historyMatches-textGray;
        padding-left: 10px;
        text-align: left;
      }

      &.align-left {
        text-align: left;
        padding-left: 10px;
      }
    }
  }

  .table-header {
    background: $liveDetail-historyMatches-white;
    font-weight: 500;
    color: $liveDetail-historyMatches-textGray;
  }

  .table-row {
    &.odd-row {
      background-color: $liveDetail-historyMatches-bgGray;
    }

    &.even-row {
      background-color: $liveDetail-historyMatches-white;
    }
  }
}

// 统一的标题行样式
.title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $liveDetail-historyMatches-borderGray;
  padding-bottom: 10px;

  .title {
    font-size: 16px;
    color: $liveDetail-historyMatches-titleColor;
    font-weight: 500;
  }

  .period-select {
    width: 90px;
    height: 28px;
    border-radius: 20px;
    border: 1px solid $liveDetail-historyMatches-grayBorder;
    color: $liveDetail-historyMatches-grayBorder;
    font-size: 12px;
    padding: 0 12px;
    outline: none;
    background-color: $liveDetail-historyMatches-white;
    cursor: pointer;

    // 自定义下拉箭头
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23B1BACC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 16px;

    &:focus {
      border-color: $liveDetail-historyMatches-grayBorder;
    }

    option {
      color: $liveDetail-historyMatches-grayBorder;
      background-color: $liveDetail-historyMatches-white;
    }
  }
}
</style>