<template>
  <div class="live-container">
    <div class="player_warpper">
      <div class="left">
        <PlayerHeader :stream="streamData.data" :anchor="anchorData.data" />
        <div class="player">
          <div class="player-controls" :class="{ 'controls-visible': isControlsVisible }">
            <div class="fullscreen-btn" @click="toggleFullScreen">
              <i :class="isFullScreen ? 'icon-exit-fullscreen' : 'icon-fullscreen'"></i>
            </div>
          </div>

          <iframe v-show="streamData.data?.status === 1" id="iframePlayer" ref="videoIframe" class="player_iframe"
            allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
            src="https://video.dunduiby.com/?isLive=1&isRobot=0&isLogined=0&isHideCtrl=0&isNeedDanmaku=0&isForceHls=0&isTwitch=0&isNeedTheaterMode=1&isNeedWaterMask=false&waterMaskImg=jingyu&isPIP=false"></iframe>
          <div v-show="streamData.data?.status === 0" class="offline-status">
            <span>{{ loading ? '正在加载直播间中...' : '主播未开播' }}</span>
          </div>
        </div>
        <div class="gift">
          <Gift />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import PlayerHeader from './player-header.vue'
import Gift from './gift.vue'
import Chat from './chat.vue'
import { defineProps, ref, reactive, watch, onMounted, onUnmounted, defineOptions } from 'vue'
import { useRoute } from 'vue-router'

// 添加组件名称
defineOptions({
  name: 'LivePlayer'
})

const props = defineProps({
  match: Object,
  streams: Object,
  anchor: Object,
  chatId: String,
  loading: {
    type: Boolean,
    default: false
  }
})
let matchData = reactive({ data: {} })
let streamData = reactive({ data: {} })
let anchorData = reactive({ data: {} })
let video_iframe_timer = null
const isFullScreen = ref(false)
const videoIframe = ref(null)
const isControlsVisible = ref(false)
let controlsTimeout = null

// 集中管理播放器状态
const playerState = reactive({
  isFullScreen: false,
  isControlsVisible: false,
  timers: {
    iframe: null,
    controls: null
  }
})

// 统一的 Timer 管理
const timerManager = {
  clear: (timerName) => {
    if (playerState.timers[timerName]) {
      clearInterval(playerState.timers[timerName])
      clearTimeout(playerState.timers[timerName])
      playerState.timers[timerName] = null
    }
  },
  clearAll: () => {
    Object.keys(playerState.timers).forEach(timerManager.clear)
  }
}

// 简化的初始化播放器函数
const initPlayer = (playAddr) => {
  if (!playAddr?.ori_flv || !playAddr?.ori_m3u8) return
  
  const playerData = {
    playAddr: {
      flv: playAddr.ori_flv,
      m3u8: playAddr.ori_m3u8,
      DPlayerID: 'iFrame_Player'
    }
  }
  
  timerManager.clear('iframe')
  playerState.timers.iframe = setInterval(() => sendMessageToIframe(playerData), 1000)
}

const sendMessageToIframe = (data) => {
  const iframe = document.getElementById('iframePlayer')
  if (!iframe) return
  
  iframe.contentWindow.postMessage(data, '*')
  iframe.addEventListener('load', () => iframe.contentWindow.postMessage(data, '*'), { once: true })
}

window.addEventListener('message', function (event) {
  if (event.data.msg === 'playing') {
    if (playerState.timers.iframe) {
      clearInterval(playerState.timers.iframe)
      playerState.timers.iframe = null
    }
  }
})

watch(
  () => props.match,
  (newProps, oldProps) => {
    matchData.data = newProps
  }
)

watch(
  () => props.streams,
  (newProps, oldProps) => {
    console.log('newProps111', newProps)
    if (newProps.playAddr?.ori_m3u8 && newProps.playAddr?.ori_flv) {
      streamData.data = newProps
      initPlayer(streamData.data.playAddr)
    }
  }
)

watch(
  () => props.anchor,
  (newProps, oldProps) => {
    anchorData.data = newProps
  }
)

// 简化的全屏切换逻辑
const toggleFullScreen = async () => {
  const iframe = videoIframe.value
  const playerContainer = iframe?.closest('.player')
  const element = iframe || playerContainer

  try {
    if (!playerState.isFullScreen) {
      await element?.requestFullscreen?.() ||
            element?.webkitRequestFullscreen?.() ||
            element?.mozRequestFullScreen?.() ||
            element?.msRequestFullscreen?.()
    } else {
      await document.exitFullscreen?.() ||
            document.webkitExitFullscreen?.() ||
            document.mozCancelFullScreen?.() ||
            document.msExitFullscreen?.()
    }
  } catch (error) {
    console.error('全屏切换失败:', error)
  }
}

const route = useRoute()

// Add route watcher
watch(
  () => route.path.id,
  (newPath, oldPath) => {
    if (newPath !== oldPath) {
      // Clean up when route changes
      if (playerState.timers.iframe) {
        clearInterval(playerState.timers.iframe)
        playerState.timers.iframe = null
      }
      // Remove event listeners
      document.removeEventListener('fullscreenchange', playerState.fullscreenHandlers.change)
      document.removeEventListener('webkitfullscreenchange', playerState.fullscreenHandlers.change)
      document.removeEventListener('mozfullscreenchange', playerState.fullscreenHandlers.change)
      document.removeEventListener('MSFullscreenChange', playerState.fullscreenHandlers.change)

      // Remove iframe
      const iframe = document.getElementById('iframePlayer')
      if (iframe) {
        iframe.src = 'about:blank' // 清空 iframe 内容
        iframe.parentNode?.removeChild(iframe) // 从 DOM 中移除 iframe
      }
    }
  },
  { immediate: true }
)

// 统一的事件管理
const eventManager = {
  fullscreenHandlers: {
    change: () => {
      playerState.isFullScreen = Boolean(
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      )
    }
  },
  
  mouseHandlers: {
    move: () => {
      playerState.isControlsVisible = true
      timerManager.clear('controls')
      playerState.timers.controls = setTimeout(() => {
        playerState.isControlsVisible = false
      }, 3000)
    }
  },

  add: () => {
    const { change } = eventManager.fullscreenHandlers
    document.addEventListener('fullscreenchange', change)
    document.addEventListener('webkitfullscreenchange', change)
    document.addEventListener('mozfullscreenchange', change)
    document.addEventListener('MSFullscreenChange', change)
    document.addEventListener('mousemove', eventManager.mouseHandlers.move)
  },

  remove: () => {
    const { change } = eventManager.fullscreenHandlers
    document.removeEventListener('fullscreenchange', change)
    document.removeEventListener('webkitfullscreenchange', change)
    document.removeEventListener('mozfullscreenchange', change)
    document.removeEventListener('MSFullscreenChange', change)
    document.removeEventListener('mousemove', eventManager.mouseHandlers.move)
  }
}

// iframe 清理函数
const cleanupIframe = () => {
  const iframe = document.getElementById('iframePlayer')
  if (iframe) {
    iframe.src = 'about:blank'
    iframe.parentNode?.removeChild(iframe)
  }
}

// 统一的清理函数
const cleanup = () => {
  timerManager.clearAll()
  eventManager.remove()
  cleanupIframe()
}

onMounted(() => {
  timerManager.clearAll()
  eventManager.add()
})

onUnmounted(cleanup)
</script>

<style scoped lang="scss">
.player_warpper {
  display: flex;
  justify-content: space-between;

  .left {
    background: $liveDetail-player-bg-white;
    border-radius: 20px 20px 20px 20px;
    width: 860px;

    .player {
      height: 600px;
      width: 100%;
      position: relative;
      border: 1px solid $liveDetail-player-border-light;
      background: $liveDetail-player-bg-dark;

      .player-controls {
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 10;
        display: flex;
        gap: 10px;
        padding: 10px;
        border-radius: 4px;
        opacity: 0;
        transition: opacity 0.3s;
      }

      .fullscreen-btn {
        cursor: pointer;
        color: $liveDetail-player-bg-white;
        padding: 5px;
        transition: all 0.3s;

        &:hover {
          color: $liveDetail-player-highlight;
        }

        i {
          font-size: 24px;
        }
      }

      &:fullscreen {
        padding: 0;
      }

      .player_iframe {
        background: $liveDetail-player-bg-dark;
        height: 100%;
        width: 100%;
        outline: none;
        border: none;
      }

      .offline-status {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
        color: $liveDetail-player-text-gray;
        width: 200px;
        text-align: center;
        height: 60px;
        line-height: 60px;
        border: 1px solid $liveDetail-player-text-gray;
        border-radius: 10px;

        // 添加加载中的动画效果
        &.loading {
          span::after {
            content: '';
            animation: dots 1.5s infinite;
          }
        }
      }

      &:hover .player-controls {
        opacity: 1;
      }

      &.controls-visible {
        opacity: 1;
      }
    }
  }
}

@keyframes dots {

  0%,
  20% {
    content: '';
  }

  40% {
    content: '.';
  }

  60% {
    content: '..';
  }

  80% {
    content: '...';
  }

  100% {
    content: '';
  }
}

:deep(.player:fullscreen) {
  width: 100vw !important;
  height: 100vh !important;
  padding: 0 !important;
  background: $liveDetail-player-bg-black !important;

  .player_iframe {
    width: 100vw !important;
    height: 100vh !important;
  }
}
</style>
