<template>
  <div class="live-container">
    <div class="inner-container">
      <div class="live-wrapper">
        <side-bar />
      </div>
      <div class="container">
        <div class="left">
          <Header :match="data.match.match_info" v-show="data.match?.match_info?.match_id" />
          <Player
            :match="playerData.match_info"
            :streams="playerData.stream_info"
            :anchor="playerData.anchor_info"
            :chatId="playerData.chat_id"
            :loading="loading"
          />
        </div>
        <div class="right">
          <Chat :chatId="data.match.chat_id" :anchorId="playerData?.anchor_info?.userId" />
        </div>
      </div>
      <div class="tab">
        <Tab />
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, onUnmounted, reactive, provide, computed, ref, toRaw } from 'vue'
import Header from './header.vue'
import Player from './player.vue'
import Chat from './chat.vue'
import Tab from './tab.vue'
import { useRoute } from 'vue-router'
import { getLiveDetail } from '@/api/live'
import SideBar from '@/components/sideBar.vue'

const route = useRoute()
let data = reactive({ match: {} })
let timeId = ref(null)
const loading = ref(true)
// Add new reactive object for player data
const playerData = reactive({
  match_info: {},
  stream_info: [],
  anchor_info: {},
  chat_id: ''
})

// 使用 computed 处理提供给子组件的数据
const providedMatchData = computed(() => {
  let info = toRaw(data.match) //? JSON.parse(JSON.stringify(data.match)) : ''
  console.log(info)
  return {
    sportId: info?.match_info?.sportId || null,
    matchId: info?.match_info?.match_id || '',
    status: info?.match_info?.status || '',
    // 其他可能需要的数据
    matchInfo: info?.match_info || {},
    streamInfo: info?.stream_info || [],
    anchorInfo: info?.anchor_info || {}
  }
})
console.log(providedMatchData, 'providedMatchData', data)

// 提供处理后的数据
provide('matchData', providedMatchData)

onMounted(() => {
  getData() //首次可能出现数据加载不及时的情况
  timeId.value = setInterval(() => {
    getData()
  }, 20000)
  window.scrollTo({
    top: 0
  })
})

onUnmounted(() => {
  clearInterval(timeId.value)
  timeId.value = null
})

const getData = async () => {
  try {
    loading.value = true
    const res = await getLiveDetail({
      roomId: route.params.id
    })
    data.match = res.data
    // Only update player data on first load
    console.log('res.data321', !playerData.chat_id || (res.data.stream_info.status !== playerData.stream_info.status))
    if (!playerData.chat_id || (res.data.stream_info.status !== playerData.stream_info.status)) {
      playerData.match_info = res.data.match_info
      playerData.stream_info = res.data.stream_info
      playerData.anchor_info = res.data.anchor_info
      playerData.chat_id = res.data.chat_id
    }
  } catch (error) {
    console.log(error, 'error')
  } finally {
    loading.value = false
  }
}
</script>
<style lang="scss" scoped>
.live-container {
  width: 100%;
  background: $liveDetail-index-bg;
  box-sizing: border-box;
  
  .inner-container {
    min-width: 1400px;
    padding-top: 30px;
  }
}

.container {
  width: 1200px;
  margin: 0 auto;
  background: $liveDetail-index-bg;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.tab {
  width: 1200px;
  margin: 0 auto;
}

.loading-wrapper {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $liveDetail-index-loading-bg;
  border-radius: 8px;
}
</style>
