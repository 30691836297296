<template>
  <div class="exponent-live">
    <div class="header">
      <div class="title">
        <p>百家指数走势</p>
<!--        <span>12312</span>-->
      </div>
      <a-select v-model:value="selectedValue" @change="() => setTrendHundredData()" class="select">
        <a-select-option :value="1">让球</a-select-option>
        <a-select-option :value="2">欧指</a-select-option>
        <a-select-option :value="3">大小</a-select-option>
      </a-select>
    </div>
    <div class="table" v-if="[1,3].includes(selectedValue)">
      <a-table
          class="ant-table-striped"
          :columns="columns"
          :data-source="trendHundredChangeData"
          :pagination="false"
          bordered
          :row-class-name="(_record: any, index:number) => (index % 2 === 1 ? null : 'table-striped')"
          size="small"
      >
        <template #bodyCell="{ column, text }">
          <template v-if="['updateTime', 'createTime'].includes(column.dataIndex)">
            <span>{{formatMilliseconds(text)}}</span>
          </template>
        </template>
      </a-table>
    </div>

    <div class="table" v-else>
      <a-table
          class="ant-table-striped"
          :columns="bigSmallColumns"
          :data-source="trendHundredChangeData"
          :pagination="false"
          bordered
          :row-class-name="(_record: any, index:number) => (index % 2 === 1 ? null : 'table-striped')"
          size="small">
      </a-table>
    </div>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue'
import type { TableColumnsType } from 'ant-design-vue'
import {useRoute} from "vue-router";
import {getMatchFootballOddsAPI} from "@/api/match";

interface DataItem {
  key: string
  league: string
  time: string
  match: string
  odds: string
  handicap: string
  result: string
}

const route = useRoute();


const selectedValue = ref<number>(1)

const columns: TableColumnsType = [
  {
    title: '赛事',
    dataIndex: 'name',
    width: 80,
    align: 'center',
  },
  {
    title: '主',
    dataIndex: 'value0ForType1',
    width: 80,
    align: 'center',
  },
  {
    title: '初值',
    dataIndex: 'ovalue',
    width: 150,
    align: 'center',
  },
  {
    title: '客',
    dataIndex: 'value0ForType2',
    width: 80,
    align: 'center',
  },
  {
    title: '时间',
    dataIndex: 'updateTime',
    width: 120,
    align: 'center',
  },
  {
    title: '主',
    dataIndex: 'valueForType1',
    width: 80,
    align: 'center',
  },
  {
    title: '初值',
    dataIndex: 'ovalue0',
    width: 150,
    align: 'center',
  },
  {
    title: '客',
    dataIndex: 'valueForType2',
    width: 80,
    align: 'center',
  },
  {
    title: '时间',
    dataIndex: 'createTime',
    width: 120,
    align: 'center',
  },
]

const bigSmallColumns = [
    // 公司	主胜	客胜	主胜	客胜
  {
    title: '公司',
    dataIndex: 'name',
    width: 80,
    align: 'center',
  },
  {
    title: '主胜',
    dataIndex: 'value0ForType1',
    width: 80,
    align: 'center',
  },
  {
    title: '客胜',
    dataIndex: 'value0ForType2',
    width: 80,
    align: 'center',
  },
  {
    title: '主胜',
    dataIndex: 'valueForType1',
    width: 80,
    align: 'center',
  },
  {
    title: '客胜',
    dataIndex: 'valueForType2',
    width: 80,
    align: 'center',
  },
]

// 让球 欧指 大小
const trendHundredData: any = ref([])
const trendHundredChangeData: any = ref([])

const setTrendHundredData = () => {
  trendHundredChangeData.value = trendHundredData.value.filter((item: any) => item.typeId === selectedValue.value)
}

function formatMilliseconds(ms: number) {
  const date = new Date(ms);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${month}-${day} ${hours}:${minutes}`;
}


const footballOddsData: any = ref()

const getFootballData = () => {
  let matchId = route.query.id
  getMatchFootballOddsAPI({matchId}).then(res => {
    footballOddsData.value = res.data || []
    let data = [...footballOddsData.value]
    let includesArr = [1,2,3]
    let bookData: any = []
    data.forEach(item => {
      if (item.coOddsWithMatchList && item.coOddsWithMatchList.length) {
        item.coOddsWithMatchList.forEach((i: any) => {
          if (includesArr.includes(i.typeId)) {
            bookData.push({
              name: item.bookName,
              ...i
            })
          }

        })
      }
    })

    trendHundredData.value = [...bookData]
  }).finally(() => {
    setTrendHundredData()
  })
}

onMounted(() => {
  getFootballData()
})
</script>

<style scoped lang="scss">
.exponent-live {
  padding: 16px;
  background: $liveDetail-exponentLive-bg;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .title {
      display: flex;
      align-items: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: $liveDetail-exponentLive-text-primary;
      span {
        margin-left: 4px;
        font-weight: 400;
        font-size: 14px;
        color: $liveDetail-exponentLive-text-success;
      }
    }

    .select {
      width: 120px;
      :deep(.ant-select-selector) {
        .ant-select-selection-item {
          color: $liveDetail-exponentLive-text-secondary;
        }
      }

    }
  }

  .table {
    :deep(.ant-table) {
      .ant-table-thead > tr > th {
        background: $liveDetail-exponentLive-bg !important;
        border-bottom: 1px solid $liveDetail-exponentLive-border-light;
        color: $liveDetail-exponentLive-text-muted !important;
        font-size: 14px !important;
        font-weight: normal !important;

        &::before {
          display: none;
        }
      }

      .ant-table-thead > tr> th:nth-of-type(1),.ant-table-thead > tr> th:nth-of-type(5),.ant-table-thead > tr> th:last-of-type {
        border-right: 1px solid $liveDetail-exponentLive-border !important;
      }
      .ant-table-thead > tr> th {
        //background-color: #772929!important;
        border-inline-end: none!important;
      }

      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        border-color: $liveDetail-exponentLive-border !important;
        color: $liveDetail-exponentLive-text-primary;
      }

      .table-striped {
        background-color: $liveDetail-exponentLive-stripe;
      }

      .ant-table-tbody > tr:hover > td {
        background-color: $liveDetail-exponentLive-stripe !important;
      }

      .red {
        color: $liveDetail-exponentLive-text-red;
      }

      .blue {
        color: $liveDetail-exponentLive-text-blue;
      }
    }


  }

}
</style>

